@keyframes sideBarOff
    0%
        margin-left: 0px
    100%
        margin-left: -250px

@keyframes sideBarOn 
    0%
        margin-left: -250px
    100%
        margin-left: 0px

aside.left-sidebar.passive
    animation: sideBarOff 0.3s linear forwards

aside.left-sidebar.active
    animation: sideBarOn 0.3s linear forwards
    
aside.left-sidebar
    position: fixed
    background: #fff
    height: 100%
    width: 250px
    display: flex
    flex-direction: column
    box-shadow: 1px 0px 20px rgb(0 0 0 / 8%)
    .user-info
        position: relative
        padding: 11px 15px
        background: rgba(0, 76, 204, 0.1)
        h3.user-name
            font-size: 18px
            font-weight: 600
            color: #004CCC
        h4.user-email
            font-size: 16px
            font-weight: 600
            color: #004CCC
        button.user-helper-dropdown
            background: transparent
            border: none
            outline: none
            position: absolute
            top: 15px
            right: 11px
            cursor: pointer
    .menu
        .menu-list
            list-style-type: none
            &__header
                background: #eee
                padding: 8px 15px
                border-right: 1px solid white
                h3
                    font-weight: 600
                    font-size: 14px
            &__item
                display: flex
                align-items: center
                padding: 13px 15px
                border-bottom: 1px solid #eee
                img
                    width: 15px
                    margin-right: 12px
                span
                    font-size: 16px
                    font-weight: 400
                    color: rgb(97, 97, 97)
