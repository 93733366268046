.footer
    width: 100%
    height: 200px
    background: #88926B
    align-items: center
    display: flex
    z-index: 999

    .container
        display: flex

        .footer-links-wrap
            display: flex

            &:first-child
                margin-left: 46px

            .footer-links-column
                margin-right: 60px
                .footer-links-list

                    .footer-link
                        margin-top: 3px

                    li
                        list-style-type: none

                        a
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #FFFFFF
                            text-decoration: none

        .footer-icons-wrap
            display: flex
            margin-left: auto
            align-items: center

            :not(:first-child)
                margin-left: 20px

            li
                list-style-type: none

            &:last-child
                margin-right: 18px

@media (min-width: 769px) and (max-width: 992px)
    .footer
        .container
            height: 100%
            .footer-links-wrap
                padding: 5px 0
                margin-right: 30px
                margin-left: 30px !important
                .footer-links-column
                    display: flex
                    align-items: center
                    margin-right: 20px
                    font-size: 10px
                    .footer-links-list
                        height: 100%
                        display: flex
                        flex-direction: column
                        justify-content: space-around
                .footer-links-column.third
                    margin-right: 0

@media (max-width: 576px)
    .footer
        position: relative
        background: #000
        align-items: start
        .container
            margin-top: 10px
            position: relative
            .footer-links-wrap
                position: relative
                margin: 0 !important
                .footer-links-column
                    position: relative
                    margin: 0
                .footer-links-column.first,.footer-links-column.second
                    display: none
                .footer-links-column.third
                    .footer-links-list
                        position: relative
                        li:not(:last-child)
                            display: none
                        li
                            position: absolute
                            top: 50px
                            left: 7px
                            width: 247px
                            a
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 11px
                                color: #434242
@media (min-width: 577px) and (max-width: 768px)
    .footer
        position: relative
        background: #000
        align-items: start
        .container
            margin-top: 10px
            position: relative
            .footer-links-wrap
                position: relative
                margin: 0 !important
                .footer-links-column
                    position: relative
                    margin: 0
                .footer-links-column.first,.footer-links-column.second
                    display: none
                .footer-links-column.third
                    .footer-links-list
                        position: relative
                        li:not(:last-child)
                            display: none
                        li
                            position: absolute
                            top: 50px
                            left: 7px
                            width: 247px
                            a
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 11px
                                color: #434242
