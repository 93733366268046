.discountContent
    word-wrap: break-word
    white-space: normal
    text-overflow: clip
    text-align: left
    position: relative
    font-family: Roboto
    font-weight: 500
    font-size: 14px
    padding: 0 5px
    &__parametrs
        margin: 5px 0
        border-top: 1px solid #55acee
        display: flex
        flex-direction: column
        ul
            list-style-type: none
            margin: 5px 0
            li
                list-style-type: none
        .parametersList
            list-style-type: none
            display: flex
            flex-direction: column
        .parametersList_condition
            border-top: 1px dashed #55acee
            display: flex
            flex-direction: column
            &__item
                list-style-type: none
                span
                    display: inline-block
                span:first-child
                    width: 120px

    &__comment
        border-top: 1px dashed #55acee
        display: flex
        flex-direction: column
        margin: 5px 0
    &__price-list
        border-top: 1px dashed #55acee
        margin: 5px 0
        display: flex
        position: relative
        ul
            list-style-type: none
            width: 50%
            span.price-title
                display: inline-block
                width: 105px
        ul.discountPrice_first-column
            display: flex
            flex-direction: column
    &__extra-condition
        border-top: 1px dashed #55acee
        margin: 5px 0
        display: flex
        ul.extra-condition-list
            display: flex
            flex-direction: column
            li.extra-condition-item
                list-style-type: none
                span:first-child
                    display: inline-block
                    width: 210px
