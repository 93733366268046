.nav-menu
    width: 204px
    height: 176px
    position: absolute
    right: 0
    top: 0
    background: #7A7A7A
    border-radius: 25px 0px 20px 25px
    z-index: 500
    .exit-btn
        cursor: pointer
        border: none
        background: transparent
        margin-top: 10px
        margin-left: 20px
    .nav-menu-list
        margin-left: 25px
        li
            position: relative
            list-style-type: none
            margin-top: 15px
            z-index: 100
            :hover
                color: #CFEE75
            button
                background: transparent
                border: none
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 14px
                color: #FFFFFF
                cursor: pointer
            a
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 14px
                color: #FFFFFF
                text-decoration: none
