@keyframes lighting-error 
    50%
        box-shadow:0 0 5px red

.sign-up-wrap
    width: 100%
    height: 100vh
    box-sizing: border-box
    position: relative
    margin: 0 auto
    display: flex
    align-items: center
    padding: 0 10px
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC
    .back-btn
        cursor: pointer
        border: none
        position: absolute
        width: 51px
        height: 36px
        background: #FFFDFD
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 35px
        justify-content: center
        display: flex
        left: 27px
        top: 21px
        align-items: center
    .sign-up-form
        max-width: 500px
        box-sizing: border-box
        width: 100%
        // height: 360px
        position: relative
        background: #FFFDFD
        margin: auto auto
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 25px 25px 0px 25px
        padding: 30px 38px 30px 42px
        .header-wrap
            display: flex
            flex-direction: row
            align-items: flex-end
            justify-content: space-between
            .info-wrap
                .header-text
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 18px
                        color: #000000
                .subheader-text
                    text-decoration: none
                    span
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        color: #969595
                    span.span_sign-in
                        margin-left: 5px
                        text-decoration: underline

            .user
                a
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                    text-decoration-line: underline
                    color: #AAD729
        input
            margin-top: 10px
            background: #FFFFFF
            border: 1px solid #B3DA44
            box-sizing: border-box
            border-radius: 30px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 16px
            color: #000000
            width: 100%
            height: 37px
            padding: 10px 20px 10px 20px
            outline: none
            &::-webkit-input-placeholder
                color: #969595
            &::-moz-placeholder
                color: #969595
        input:focus
            border-color: orange !important
        input.input_phone
            caret-color: transparent
        input.invalid-value
            animation: lighting-error 2s ease
            border-color: red
        .password-wrap
            .password-label-input-wrapper
                position: relative
                margin-top: 10px
                label
                    color: #534e4e
                    margin-left: 20px
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                input
                    margin-top: 5px
                span
                    background: white
                    cursor: pointer
                    height: 13px
                    right: 13px
                    bottom: 13px
                    position: absolute
                    img
                        height: 13px
                        width: 13px

        .proceed-btn
            margin-top: 20px
            border: none
            cursor: pointer
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 33.85%, #B3DA44 100%), #ECECEC
            border-radius: 30px 0px 20px 30px
            height: 35px
            width: 100%
            text-align: center
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 16px
            color: #000000
        .links-list
            width: 100%
            margin-top: 25px
            display: flex
            flex-direction: column
            align-items: center
            li
                width: 174px
                list-style-type: none
                margin-bottom: 6px
                a
                    text-align: left
                    text-decoration: none
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 12px
                    color: #969595

@media (max-width: 400px)
    .sign-up-wrap
        .sign-up-form
            .header-wrap
                display: flex
                justify-content: space-between
                align-items: flex-start
                .info-wrap
                    .header-text
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #000000
                    .subheader-text
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 10px
                            color: #969595
                .user
                    margin-top: 30px
                    a
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 11px
                        text-decoration-line: underline
                        color: #AAD729
