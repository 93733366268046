@mixin link-hover-before
    display: block
    content: ""
    position: absolute
    left: 9px
    top: 17px
    width: 0
    height: 0
    border-bottom: 8px solid #7A7A7A
    border-right: 5px solid transparent
    z-index: 1

@mixin link-hover-after
    content: attr(data-title)
    position: absolute
    left: 9px
    top: 24px
    width: 102px
    text-align: center
    border-radius: 0px 35px 35px 70px
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    z-index: 1
    background: #7A7A7A
    padding: 5px 10px

*
    padding: 0
    margin: 0
.mainpage-wrap
    height: 100vh
    overflow: auto

.container
    max-width: 1400px
    margin: 0 auto
    width: 100%
    z-index: 0

.navbar
    position: relative
    display: flex
    justify-content: flex-end
    background: #131212
    height: 71px
    width: 100%
    z-index: 999

    .container
        display: flex
        justify-content: flex-end
        .nav-logo
            position: absolute
            left: 0
            top: 0
            display: none
        .nav-links
            font-family: Roboto
            font-style: normal
            font-weight: 300
            font-size: 10px
            color: #E0FEF9
            display: flex
            display: none
            li
                list-style-type: none
            li:not(:first-child)
                margin-left: 10px
    .helpWrap
        color: white
        display: flex
        justify-content: flex-end
        align-items: center
        // padding-top: 16px
        margin-right: 47px

        .comment
            a
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 18px
                color: #FFFFFF
                text-decoration: none

        li
            list-style-type: none

        &__icons
            margin-left: 18px
            z-index: 0
            position: relative
        // including mixins
        .favorite-icon:hover::after, .notification-icon:hover::after, .post-icon:hover::after
            @include link-hover-after

        .favorite-icon:hover::before, .notification-icon:hover::before, .post-icon:hover::before
            @include link-hover-before

        .post-icon:hover::before, .post-icon:hover::after
            left: 8px !important

    .user-button
        display: flex
        align-self: center
        position: relative
        margin-right: 18px
        &__btn
            position: relative
            width: 75px !important
            display: flex
            align-self: center
            background-color: Transparent
            background-repeat: no-repeat
            border: none
            cursor: pointer
            overflow: hidden
            .user-wrap
                display: flex
                justify-content: center
                width: 75px
                height: 40px
                border: 1px solid #FFFDFD
                box-sizing: border-box
                border-radius: 20px 0px 20px 20px

                .divIcon
                    width: 19px
                    margin-right: 7px

                .userIcon
                    width: 28px
                    z-index: 111

.mainpage.main
    position: relative
    z-index: 1
    width: 100%
    height: calc(100vh - 86px - 71px)
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC
    overflow: auto
    .container
        height: auto
        max-width: 1348px
        width: 100%
        position: relative
        img
            z-index: 0

        .bg-logo
            position: absolute
            width: 489px
            height: 208px
            margin-left: calc( -1 * (100vw - 1400px) / 2)
            z-index: 0

        .bg-arch
            height: calc(100vh - 86px - 71px)
            position: absolute
            right: 0px

        .main-titles
            position: relative
            display: flex
            margin-left: 580px
            // padding-top: 50px
            padding-top: 100px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 24px
            line-height: 28px
            z-index: 1
            color: #000000
            li
                list-style-type: none
            &__spec
                margin-right: 65px

        .main-intro
            position: relative
            margin-left: 68px
            width: 330px
            margin-top: 164px
            .bg-logo
                display: none
            &__article
                text-align: left
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 48px
                color: #000000

            &__paragraph
                text-align: left
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 16px
                color: #1B1818

        .main-wrap
            width: 100%
            position: relative
            display: flex
            &__mobile-header
                width: 200px
                display: none
                h1
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 36px
                    color: #000000
                    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25)
            .main-card
                position: relative
                display: flex
                justify-content: space-evenly
                margin-top: 49px
                height: 56px
                width: 828px
                background: #FFFDFD
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                border-radius: 35px
                margin-right: 18px
                &__header
                    display: none
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    color: #000000
                    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
                .form-group.town
                    z-index: 999
                    .DropDownContainer
                        position: relative
                        .DropDownHeader
                            width: 100%
                            border: none
                            outline: none
                            font-family: Roboto
                            font-style: normal
                            font-weight: 300
                            font-size: 18px
                            color: #969595
                            cursor: pointer
                        .DropDownListContainer
                            width: 100%
                            position: absolute
                            z-index: 999
                            .DropDownList
                                margin-left: -10px
                                font-family: Roboto
                                font-style: normal
                                font-weight: 300
                                font-size: 16px
                                cursor: pointer
                                &:first-child
                                    padding-top: 10px
                                .ListItem
                                    padding-left: 10px
                                    border-radius: 10px
                                    color: #EFEEEE
                                    background-color: #7A7A7A
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    list-style: none
                                    margin-bottom: 2px
                .form-group
                    margin-top: 7px
                    width: 110px
                    z-index: 7
                    label
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        color: #000000
                    input
                        border: none
                        width: 97px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 300
                        font-size: 18px
                        color: #969595
                        outline: none
                        &:focus
                            color: #000000
                .form-group.date-form
                    width: 272px
                    z-index: 999
                    label
                        display: inline-block
                        width: 116px
                        margin-right: 20px

                .form-group.btn
                    align-self: center
                    margin-top: 0px
                    width: 150px
                    height: 36px
                    display: flex
                    .form-group__btn
                        cursor: pointer
                        display: flex
                        width: 150px
                        height: 36px
                        border: none
                        justify-content: center
                        align-items: center
                        // padding: 7px 50px
                        background: #030303
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 30px
                        text-decoration: none
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 18px
                        color: #FFFFFF
                .error-info
                    position: absolute
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 14px
                    color: red
                    top: 100px
                    left: 10px

        .useful-link
            margin-top: 22px
            padding-bottom: 22px

            &__btn
                max-width: 175px
                width: 100%
                height: 30px
                border: 1px solid #FFFDFD
                box-sizing: border-box
                border-radius: 30px
                font-family: Roboto
                font-style: normal
                font-weight: 500
                font-size: 16px
                color: #FFFFFF
                background-color: Transparent
                background-repeat: no-repeat
                cursor: pointer
                overflow: hidden
                margin-left: 68px

    .partnership-link
        position: fixed
        right: 0px
        bottom: 100px
        &__btn
            margin-right: 28px
            display: block
            width: 159px
            height: 32px
            border: none
            background: #E6DEC5
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
            border-radius: 30px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 14px
            color: #000000

// datepicker
.DateRangePickerInput_arrow.DateRangePickerInput_arrow_1
    display: none
@media (max-width: 576px)
    .mainpage-wrap
        max-width: 576px
        width: 100%
        position: relative
        background: #000
        .navbar
            background: #000
            .container
                .nav-logo
                    position: inline
                    display: block
                    z-index: 0
                    height: 71px
                    overflow: hidden
                    left: -10px
                    img
                        overflow: hidden
                .nav-links
                    display: flex
                    position: absolute
                    left: 50px
                    top: 10px
                .helpWrap
                    position: relative
                    margin: 0
                    z-index: 111
                    li:first-child
                        display: none
                    &__icons
                        margin-left: 12px
                .user-button
                    width: 48px
                    margin-left: 23px
                    margin-right: 11px
                    &__btn
                        .user-wrap
                            display: flex
                            align-items: center
                            border: 1px solid #E5E5E5
                            box-sizing: border-box
                            border-radius: 20px 0px 35px 20px
                            width: 48px
                            height: 26px
                            .divIcon
                                width: 12px
                            .userIcon
                                width: 17px
                                height: 19px
                // .user-button
                //     &__btn
                //         .user-wrap
                //             border: 1px solid #E5E5E5
                //             box-sizing: border-box
                //             border-radius: 20px 0px 35px 20px

    .bg-logo
        display: none
    .bg-arch
        display: none
    .partnership-link
        display: none
    .useful-link
        display: none
    .mainpage-wrap
        box-shadow: none
        box-sizing: border-box
        padding-left: 10px
        .main.mainpage
            z-index: 11
            width: 100%
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
            padding: 0 0 0 0
            box-sizing: border-box
            .container
                z-index: 11
                position: relative
                // height: calc(100vh - 86px - 71px)
                width: 100%
                .main-titles
                    z-index: 111
                    position: relative
                    width: 100%
                    display: flex
                    padding: 0
                    background: #000
                    font-family: Roboto
                    font-style: normal
                    font-weight: 300
                    font-size: 12px
                    color: #C4C4C4
                    justify-content: space-around
                    li
                        width: 100%
                        margin: 0
                        display: flex
                        justify-content: center
                        align-self: center
                    .lodging
                        background: #000000
                        border: 1px solid #DADADA
                        border-bottom: 0px
                        box-sizing: border-box
                        border-radius: 10px 180px 0px 0px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 500
                        font-size: 12px
                        color: #E6FEA1
                        margin: 0
                .main-wrap
                    flex-wrap: wrap
                    flex-direction: column
                    padding: 50px 39px 0 30px
                    box-sizing: border-box
                    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
                    &__mobile-header
                        display: block
                        height: 100px
                        h1
                            text-align: left
                    .main-intro
                        margin: 10px 0 0 0 !important
                        width: 70px
                        height: 40px
                        &__article
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 10px
                            color: #434242
                        &__paragraph
                            display: none
                    .main-card
                        // position: relative
                        margin: 27px 0 88px auto
                        max-width: 283px
                        width: 100%
                        height: 185px
                        display: block
                        box-sizing: border-box
                        padding: 15px 0 18px 24px
                        &__header
                            display: block
                            width: 100%
                        .date-form
                            z-index: 999
                            max-width: 350px
                            width: 100% !important
                            label:first-child
                                margin-right: 40px
                            label
                                width: 80px !important
                                margin: 0
                        .form-group
                            margin: 3px 0 0 0
                            label
                                font-family: Roboto
                                font-style: normal
                                font-weight: 500
                                font-size: 11px
                                color: #434242
                            input
                                box-sizing: border-box
                                padding-left: 8px
                                background: #FFFFFF
                                box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                                border-radius: 30px
                                font-family: Roboto
                                font-style: normal
                                font-weight: 300
                                font-size: 16px
                                color: #969595
                                width: 80px
                                height: 23px

                            .DropDownContainer
                                .DropDownHeader
                                    display: flex
                                    align-items: center
                                    box-sizing: border-box
                                    padding-left: 8px
                                    background: #FFFFFF
                                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 30px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: 300
                                    font-size: 16px
                                    color: #969595
                                    width: 100px
                                    height: 23px
                                .DropDownListContainer
                                    .DropDownList
                                        margin-left: -5px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: 300
                                        font-size: 16px
                                        cursor: pointer
                                        &:first-child
                                            padding-top: 5px
                                        .ListItem
                                            display: flex
                                            align-items: center
                                            width: 100px
                                            height: 20px
                                            padding-left: 13px
                                            border-radius: 10px
                                            color: #EFEEEE
                                            background-color: #7A7A7A
                                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                            list-style: none
                                            margin-bottom: 2px

                        .error-info
                            position: absolute
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: red
                            top: 250px
                            left: 25px
                        .form-group.btn
                            position: absolute
                            bottom: 20px
                            right: 39px
                            background: #000000
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            height: 28px
                            .form-group__btn
                                height: 28px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 14px
                                color: #FFFFFF
                                border: none
    .footer
        margin-left: -1px

@media ( max-width: 1400px)
    .mainpage-wrap
        .main.mainpage
            .container
                .bg-logo
                    margin-left: 0
    .mainpage-wrap
        .main.mainpage
            .container
                .main-titles
                    margin: 0 100px 0 auto
                    justify-content: flex-end

    .main-wrap
        max-width: 1400px
        position: relative
        display: flex
        flex-wrap: wrap
        flex-wrap: wrap-reverse

    .main-card
        margin: 49px auto

    .main-intro
        margin-top: 0 !important
        margin-right: 500px

@media (min-width: 769px) and (max-width: 992px)
    .bg-logo
        display: none
    .mainpage-wrap
        .main.mainpage
            .container
                .main-titles
                    box-sizing: border-box
                    padding-left: 50px
                    justify-content: flex-start
                    padding-top: 20px
                    width: 100%
                .main-wrap
                    flex-wrap: wrap
                    margin-top: 20px
                    flex-direction: row
                    height: 400px
                    .main-intro
                        margin-right: 0
                        margin-left: 50px
                        width: 250px
                        .bg-logo
                            position: relative
                            display: block
                            margin: 0 0 0 -50px
                            // width: 300px
                        h2
                            font-size: 36px
                    .main-card
                        padding: 0 30px
                        flex-direction: column
                        margin: auto auto
                        max-width: 350px
                        width: 100%
                        height: 300px
                        min-height: 56px
                        .form-group.btn
                            align-self: flex-end
                        .form-group.date-form
                            z-index: 111
                            width: 350px
                            label
                                margin: 0
                                width: 50%
                        .form-group
                            
                            .DropDownContainer
                                position: relative
                                z-index: 999
                                .DropDownHeader
                                    width: 100%
                                    border: none
                                    outline: none
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: 300
                                    font-size: 18px
                                    color: #969595
                                    cursor: pointer
                                .DropDownListContainer
                                    width: 100%
                                    position: absolute
                                    z-index: 999
                                    .DropDownList
                                        margin-left: -10px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: 300
                                        font-size: 16px
                                        cursor: pointer
                                        &:first-child
                                            padding-top: 7px
                                        .ListItem
                                            height: 25px
                                            display: flex
                                            align-items: center
                                            padding-left: 10px
                                            border-radius: 10px
                                            color: #EFEEEE
                                            background-color: #7A7A7A
                                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                            list-style: none
                                            margin-bottom: 2px

                        .error-info
                            position: absolute
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: red
                            top: 320px
                            left: 30px

@media (min-width: 577px) and (max-width: 768px)
    .mainpage-wrap
        height: 100vh
        max-width: 768px
        width: 100%
        position: relative
        background: #000
        .navbar
            background: #000
            .container
                .nav-logo
                    position: inline
                    display: block
                    z-index: 0
                    height: 71px
                    overflow: hidden
                    left: -10px
                    img
                        overflow: hidden
                .nav-links
                    display: flex
                    position: absolute
                    left: 50px
                    top: 10px
                .helpWrap
                    li:first-child
                        display: none
                .user-button
                    &__btn
                        .user-wrap
                            border: 1px solid #E5E5E5
                            box-sizing: border-box
                            border-radius: 20px 0px 35px 20px

    .bg-logo
        display: none
    .bg-arch
        display: none
    .partnership-link
        display: none
    .useful-link
        display: none
    .mainpage-wrap
        box-shadow: none
        box-sizing: border-box
        padding-left: 10px
        .main.mainpage
            z-index: 11
            width: 100%
            // height: auto
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
            padding: 0 0 0 0
            box-sizing: border-box
            .container
                z-index: 11
                position: relative
                // height: calc(100vh - 86px - 71px)
                width: 100%
                .main-titles
                    z-index: 111
                    position: relative
                    width: 100%
                    display: flex
                    padding: 0
                    background: #000
                    font-family: Roboto
                    font-style: normal
                    font-weight: 300
                    font-size: 12px
                    color: #C4C4C4
                    justify-content: space-around
                    li
                        width: 100%
                        margin: 0
                        display: flex
                        justify-content: center
                        align-self: center
                    .lodging
                        background: #000000
                        border: 1px solid #DADADA
                        border-bottom: 0px
                        box-sizing: border-box
                        border-radius: 10px 180px 0px 0px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 500
                        font-size: 12px
                        color: #E6FEA1
                        margin: 0
                .main-wrap
                    flex-wrap: wrap
                    flex-direction: column
                    padding: 50px 39px 0 30px
                    box-sizing: border-box
                    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
                    &__mobile-header
                        width: auto
                        display: block
                        h1
                            font-size: 48px
                            text-align: left
                    .main-intro
                        margin: 10px 0 0 0 !important
                        width: 180px
                        &__article
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 24px
                            color: #434242
                        &__paragraph
                            display: none
                    .main-card
                        position: relative
                        margin: 27px 0 88px auto
                        width: 350px
                        height: 200px
                        display: block
                        box-sizing: border-box
                        padding: 15px 0 18px 24px
                        &__header
                            display: block
                            width: 100%
                        .date-form
                            z-index: 111
                            max-width: 350px
                            width: 100% !important
                            label:first-child
                                margin-right: 20px
                            label
                                width: 116px !important
                                margin: 0
                        .form-group
                            margin: 3px 0 0 0
                            label
                                font-family: Roboto
                                font-style: normal
                                font-weight: 500
                                font-size: 11px
                                color: #434242
                            input
                                box-sizing: border-box
                                padding-left: 8px
                                background: #FFFFFF
                                box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                                border-radius: 30px
                                font-family: Roboto
                                font-style: normal
                                font-weight: 300
                                font-size: 12px
                                color: #969595
                                width: 116px
                                height: 25px
                            .DropDownContainer
                                .DropDownHeader
                                    display: flex
                                    align-items: center
                                    box-sizing: border-box
                                    padding-left: 8px
                                    background: #FFFFFF
                                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 30px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: 300
                                    font-size: 12px
                                    color: #969595
                                    width: 70px
                                    height: 20px
                                .DropDownListContainer
                                    .DropDownList
                                        margin-left: -5px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: 300
                                        font-size: 12px
                                        cursor: pointer
                                        &:first-child
                                            padding-top: 5px
                                        .ListItem
                                            display: flex
                                            align-items: center
                                            width: 70px
                                            height: 20px
                                            padding-left: 13px
                                            border-radius: 10px
                                            color: #EFEEEE
                                            background-color: #7A7A7A
                                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                            list-style: none
                                            margin-bottom: 2px

                        .error-info
                            position: absolute
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: red
                            top: 220px
                            left: 10px
                        .form-group.btn
                            position: absolute
                            bottom: 10px
                            right: 15px
                            width: 100px
                            height: 24px
                            .form-group__btn
                                height: 24px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 14px
                                color: #FFFFFF
