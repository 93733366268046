input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

.main-preloader
    display: flex
    width: 100vw
    height: 100vh
    position: relative
    align-items: center
    justify-content: center

.houseslist-navbar
    position: fixed
    display: block
    top: 0
    width: 100%
    z-index: 999
    position: relative
    .container
        max-width: calc(100% - 100px)

.main.houseslist
    // margin-top: 119px
    position: relative
    display: block
    z-index: 1
    overflow: hidden
    // height: calc(100vh - 86px)

    .container
        max-width: calc(100% - 50px)
        width: 100%
        display: flex
        margin: 0 0 0 auto
        .main-titles
            display: none
        .houses-list
            position: relative
            width: calc( 1400px - 560px)
            .subhouses-block
                display: none
                margin: 300px auto 50px
                width: 80%
            .header-text
                position: relative
                margin-top: 39px
                h1
                    text-align: left
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 26px
                    color: #000000
                p
                    text-align: left
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 14px
                    color: #000000

            .subheader
                display: flex
                margin-top: 29px
                z-index: 1000
                &__location
                    display: block
                    width: 124px
                    height: 29px
                    position: relative

                    button
                        width: 100%
                        height: 100%
                        text-align: center
                        border-radius: 35px
                        background: #FFFDFD
                        border: none
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        background-color: Transparent
                        background-repeat: no-repeat
                        cursor: pointer
                        overflow: hidden
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        color: #434242

                &__filters
                    width: 94px
                    height: 29px
                    margin-left: 19px
                    display: block
                    position: relative
                    z-index: 222
                    &__btn
                        width: 100%
                        height: 100%
                        border: none
                        text-align: center
                        background: #969595
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 35px
                        cursor: pointer
                        overflow: hidden
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        color: #FFFFFF

                &__cost
                    margin-left: 44px
                    display: flex
                    align-items: center
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px
                        color: #434242
                    &__interval
                        margin-left: 10px
                        display: flex
                        align-items: center
                        label
                            margin-right: 6px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 12px
                            color: #434242
                        input
                            width: 71px
                            height: 25px
                            background: #FFFDFD
                            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 30px
                            background-color: Transparent
                            background-repeat: no-repeat
                            cursor: pointer
                            border: none
                            outline: none
                            text-align: center
            .houses
                &__wrap
                    display: flex
                    margin-top: 34px
                    margin-bottom: 34px
                    width: 808.8px
                    .house-image
                        position: relative
                        width: 340px
                        height: 220px
                        p
                            height: 100%
                            width: 100%
                        .slide-image-wrap
                            overflow: hidden
                            height: 100%
                            width: 100%
                            .slick-slider
                                display: flex
                                height: 100%
                                width: 100%
                                .slick-list
                                    display: flex
                                    height: 100%
                                    width: 100%
                                    .slick-track
                                        height: 100%
                                        display: flex
                                        div
                                            height: 100%
                                            height: 100%
                            img
                                width: 100%
                                height: 100%
                            .prewClick,.nextClick
                                z-index: 111
                                cursor: pointer
                                height: 25px
                                width: 15px
                                background: Transparent
                                border: none
                                outline: none
                            button
                                background: Transparent
                                border: none
                                outline: none
                                width: 100%
                                height: 100%
                                img
                                    width: 100%
                                    height: 100%
                            .prewClick
                                cursor: pointer
                                position: absolute
                                left: 25px
                                bottom: 43%
                            .nextClick
                                cursor: pointer
                                position: absolute
                                right: 25px
                                bottom: 43%
                        img
                            width: 340px
                            height: 220px
                            border-radius: 35px
                        .rating-wrap
                            display: none
                            img
                                width: 23px
                                height: 22px
                            p
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 13px
                                color: #FFFFFF
                        .house-info-name
                            display: none
                        .house-info-cost
                            display: none

                        &__favorite-icon
                            position: absolute
                            right: 33px
                            top: 21px
                            border-radius: 0px
                            border: none
                            background-color: Transparent
                            background-repeat: no-repeat
                            cursor: pointer
                            overflow: hidden
                            height: 24px
                            width: 26px
                            img
                                height: 24px
                                width: 26px
                                border-radius: 0px

                    .house-info
                        margin-left: 25px
                        display: block
                        position: relative
                        &__wrap
                            display: flex
                            justify-content: space-between
                            &__name
                                margin: 0
                                display: block
                                float: left
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 18px
                                color: #1B1818
                                width: 290px
                                h2
                                    font-style: normal
                                    font-weight: normal
                                &__distance
                                    display: flex
                                    align-items: center
                                    margin-top: 17px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 13px
                                    color: #969595
                                    img
                                        margin-left: 5px
                            &__cost
                                margin-top: 22px
                                display: block
                                // margin-right: 22px
                                p
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #434242
                                .result-cost
                                    margin-top: 5px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 11px
                                    color: #AAD729
                        &__description
                            margin-top: 29px
                            margin-right: 78px
                            position: relative
                            display: block
                            height: 28px
                            overflow: hidden
                            box-sizing: border-box
                            z-index: 1
                            p
                                position: relative
                                display: inline-block
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 12px
                                color: #434242
                        &__description-btn
                            margin-top: 2px
                            z-index: 11
                            position: relative
                            button.button
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 11px
                                text-decoration-line: underline
                                color: #969595 !important
                                cursor: pointer
                                background: Transparent
                                border: none
                                outline: none
                        &__reservation
                            display: flex
                            margin-top: 21px
                            float: right
                            margin-right: 26px
                            height: 29px
                            width: 147px
                            border: none
                            background: #434242
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            justify-content: center
                            align-items: center
                            .reservation-btn
                                cursor: pointer
                                background: Transparent
                                border: none
                                text-decoration: none
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 14px
                                color: #FFFDFD

        .map
            display: block
            max-width: calc(100% - 1400px + 560px)
            width: 100%
            height: calc(100vh)
            img
                height: 100%
                max-width: 560px
                width: 100%
            .ymaps-b-balloon.ymaps-i-custom-scroll
                .ymaps-b-balloon__content
                    a
                        text-decoration: none
                        color: blue
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 14px

// for adequate scrolling map ♥♥
.sticky
    position: fixed
    z-index: 101
    // left: 880px - 50.4px

.stop
    position: relative
    z-index: 101

// end ♥♥

@media (min-width: 1201px) and (max-width: 1348px)
    .main.houseslist
        .container
            .houses-list
                padding-left: 46px

@media ( max-width: 1200px)
    .houseslist-navbar
        .Navbar
            .container
                .navbar-card-wrap
                    position: absolute
                    top: 150px
                    left: 20%
    .main.houseslist
        margin-top: 100px
        min-height: calc(100vh - 97px - 86px)
        .container
            min-height: calc(100vh - 97px - 86px)
            margin: 0 auto
            .houses-list
                margin: 0 auto
                width: auto
            .map
                display: none

@media ( max-width: 1200px)
    .houseslist-navbar
        .Navbar
            .container
                .navbar-card-wrap
                    position: absolute
                    top: 150px
                    left: 20%
    .main.houseslist
        margin-top: 100px
        .container
            margin: 0 auto
            .houses-list
                margin: 0 auto
                width: auto
            .map
                display: none

@media ( max-width: 992px)
    .houseslist-navbar
        .Navbar
            .container
                max-width: 100%
                .navbar-card-wrap
                    position: absolute
                    top: 150px
                    left: 15%
    .main.houseslist
        margin-top: 100px
        .container
            margin: 0 auto
            .houses-list
                margin: 0 auto
                width: auto
                .houses
                    &__wrap
                        width: auto
                        .house-info
                            &__wrap
                                &__name
                                    h2
                                        font-size: 28px
                                &__cost
                                    display: flex
                                    margin: 0
                                    flex-direction: column
                                    align-items: flex-start
                                    justify-content: flex-end
                            &__description
                                width: 350px
                                margin-right: 0
            .map
                display: none
@media (min-width: 577px) and (max-width: 768px)
    .houseslist-navbar
        max-width: 768px
        width: 100%
        position: relative
        height: 71px
        .Navbar
            height: 71px
            .liked-btn
                top: 85px
                display: flex
                border: none
            .container
                .navbar-card-wrap
                    height: 32px
                    padding: 0 15px
                    position: absolute
                    top: 85px
                    left: 7%
                    background: #FFFDFD
                    border: 1px solid #CFEE75
                    box-sizing: border-box
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                    border-radius: 35px
                    width: 80%
                    .form-group
                        margin: 0
                        width: 70px
                        input
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 16px
                            color: #434242
                        button
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #434242
                        .DropDownContainer
                            position: relative
                            .DropDownHeader
                                width: 100%
                                border: none
                                outline: none
                                border: none
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 18px
                                color: #434242
                                cursor: pointer
                            .DropDownListContainer
                                width: 100%
                                position: absolute
                                z-index: 999
                                .DropDownList
                                    margin-left: -10px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: 300
                                    font-size: 16px
                                    cursor: pointer
                                    &:first-child
                                        padding-top: 10px
                                    .ListItem
                                        padding-left: 10px
                                        border-radius: 10px
                                        color: #EFEEEE
                                        background-color: #7A7A7A
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                        list-style: none
                                        margin-bottom: 2px
                    .second-group
                        width: 180px
    .main.houseslist
        margin: 0
        padding-left: 10px
        background: #000
        .container
            background: #fff
            flex-direction: column
            max-width: 100%
            .main-titles
                height: 30px
                display: flex
                z-index: 111
                position: relative
                width: 100%
                display: flex
                padding: 0
                background: #000
                font-family: Roboto
                font-style: normal
                font-weight: 300
                font-size: 16px
                color: #C4C4C4
                justify-content: space-around
                li
                    width: 100%
                    margin: 0
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 30px
                .lodging
                    background: #000000
                    border: 1px solid #DADADA
                    border-bottom: 0px
                    box-sizing: border-box
                    border-radius: 10px 180px 0px 0px
                    font-family: Roboto
                    font-style: normal
                    font-weight: 500
                    font-size: 16px
                    color: #E6FEA1
                    margin: 0
            .houses-list
                max-width: 768px
                width: 100%
                margin: 35px 0 0 0
                .subhouses-block
                    display: flex
                    flex-direction: column
                    &__header
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 28px
                            color: #000000
                            text-align: center
                    &__subheader
                        margin-top: 15px
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            text-align: center
                            color: #000000
                    &__btn
                        margin: 15px auto 0
                        .write-btn
                            background: #AAD729
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            width: 150px
                            height: 32px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            border: none
                            color: #000000
                .header-text
                    display: none
                .subheader
                    position: relative
                    &__location
                        display: none
                    &__cost
                        display: none
                    &__filters
                        position: block
                        z-index: 111
                        width: 150px
                        margin-left: 25px
                        margin-bottom: 10px
                        &__btn
                            background: #AAD729
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #000000
                            a
                                z-index: 111
                .houses
                    max-width: 768px
                    width: 100%
                    .lazyload-wrapper
                        height: 400px
                    &__wrap
                        height: 400px
                        margin: 10px 0
                        width: 100%
                        // position: relative
                        padding: 0 25px
                        box-sizing: border-box
                        .house-image
                            position: relative
                            width: 100%
                            height: 400px
                            .slide-image-wrap
                                position: relative
                                a
                                    height: 400px !important
                                    img
                                        width: 100%
                                        height: 400px
                                        border-radius: 25px 10px 10px 0px
                            &__favorite-icon
                                left: 33px
                                width: 35px
                                img
                                    width: 35px
                                    border-radius: 0 !important
                            .rating-wrap
                                display: flex
                                position: absolute
                                bottom: 24px
                                right: 23px
                                img
                                    width: 50px
                                    height: 40px
                                p
                                    margin-top: 4px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 20px
                                    color: #FFFFFF
                            .house-info-name
                                display: block
                                position: absolute
                                bottom: 22px
                                left: 21px
                                h2
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 40px
                                    line-height: 22px
                                    color: #FFFFFF
                                &__distance
                                    margin-top: 5px
                                    display: flex
                                    align-items: center
                                    span
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 24px
                                        color: #C4C4C4
                                    img
                                        height: 15px
                                        margin-left: 5px
                                        width: 14px
                                        border-radius: 0 !important
                            .house-info-cost
                                display: flex
                                align-items: center
                                justify-content: center
                                flex-direction: column
                                background: #000000
                                border-radius: 40px 0px 0px 40.5px
                                width: 200px
                                height: 60px
                                position: absolute
                                top: 65%
                                right: 0
                                p
                                    width: 100%
                                    text-align: center
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 20px
                                    color: #FFFFFF
                                .result-cost
                                    width: 100%
                                    text-align: center
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #B3DA44

                        .house-info
                            display: none

            .map
                display: none

@media (max-width: 576px)
    .houseslist-navbar
        max-width: 576px
        width: 100%
        position: relative
        height: 71px
        .Navbar
            height: 71px
            .liked-btn
                z-index: 1
                top: 85px
                display: flex
                border: none
            .container
                .navbar-card-wrap
                    height: auto
                    // max-width: 320px
                    width: 100%
                    padding: 15px 15px
                    position: absolute
                    top: 85px
                    left: 10%
                    background: #FFFDFD
                    border: 1px solid #CFEE75
                    box-sizing: border-box
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                    border-radius: 35px
                    width: 75%
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    .form-group.adapt-hide
                        display: none
                    .first-group-wrap
                        width: 100%
                        display: flex
                        // justify-content: space-between
                        .form-group:first-child
                            margin-right: 10%
                        .form-group.guest
                            display: block
                            margin: 0
                        .form-group
                            width: 40%
                            input
                                width: 100%

                            .DropDownContainer
                                position: relative
                                .DropDownHeader
                                    display: flex
                                    align-items: center
                                    height: 28px
                                    background: #FFFFFF
                                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 30px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #434242
                                    padding-left: 7px
                                .DropDownListContainer
                                    width: 100%
                                    position: absolute
                                    z-index: 999
                                    .DropDownList
                                        margin-left: -3px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: 300
                                        font-size: 16px
                                        cursor: pointer
                                        &:first-child
                                            padding-top: 10px
                                        .ListItem
                                            padding-left: 10px
                                            border-radius: 10px
                                            color: #EFEEEE
                                            background-color: #7A7A7A
                                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                            list-style: none
                                            margin-bottom: 2px

                    .form-group:not(:first-child)
                        margin: 10px 0 0 0
                    .form-group
                        margin: 0
                        width: 100%
                        label
                            font-size: 14px
                            display: block
                            width: auto
                            margin-bottom: 5px
                        input
                            width: 100px
                            height: 28px
                            background: #FFFFFF
                            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 30px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 16px
                            color: #434242
                            margin: 0
                            padding-left: 7px
                        button
                            position: relative
                            margin: 0 20px 0 auto
                            display: block
                            width: 150px
                            height: 28px
                            background: #000000
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 16px
                            color: #FFFFFF
                    .second-group
                        display: inline-block
                        width: 100%
                        label
                            width: 40%
                            margin: 0 0 5px 0
                            float: left
                        label:first-child
                            margin-right: 10%
                        .DateRangePicker.DateRangePicker_1
                            margin: 0
                            width: 100%
    .main.houseslist
        margin: 0
        padding-left: 10px
        background: #000
        .container
            background: #fff
            flex-direction: column
            max-width: 100%
            .main-titles
                height: 22px
                display: flex
                z-index: 111
                position: relative
                width: 100%
                display: flex
                padding: 0
                background: #000
                font-family: Roboto
                font-style: normal
                font-weight: 300
                font-size: 12px
                color: #C4C4C4
                justify-content: space-around
                li
                    width: 100%
                    margin: 0
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 22px
                .lodging
                    background: #000000
                    border: 1px solid #DADADA
                    border-bottom: 0px
                    box-sizing: border-box
                    border-radius: 10px 180px 0px 0px
                    font-family: Roboto
                    font-style: normal
                    font-weight: 500
                    font-size: 12px
                    color: #E6FEA1
                    margin: 0
            .houses-list
                max-width: 576px
                width: 100%
                margin: 200px 0 0 0
                .subhouses-block
                    display: flex
                    flex-direction: column
                    &__header
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 16px
                            color: #000000
                            text-align: center
                    &__subheader
                        margin-top: 15px
                        p
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 12px
                            line-height: 14px
                            text-align: center
                            color: #000000
                    &__btn
                        margin: 15px auto 0
                        .write-btn
                            background: #AAD729
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            width: 150px
                            height: 28px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            border: none
                            color: #000000
                .header-text
                    display: none
                .subheader
                    position: relative
                    &__location
                        display: none
                    &__cost
                        display: none
                    &__filters
                        position: block
                        z-index: 111
                        width: 150px
                        margin-left: 25px
                        margin-bottom: 10px
                        &__btn
                            background: #AAD729
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #000000
                            a
                                z-index: 111
                .houses
                    max-width: 576px
                    width: 100%
                    .lazyload-wrapper
                        height: 70vw
                    &__wrap
                        margin: 10px 0
                        width: 100%
                        padding: 0 25px
                        box-sizing: border-box
                        height: 70vw
                        .house-image
                            position: relative
                            width: 100%
                            height: 70vw
                            img
                                height: 70vw
                                width: 100%
                                border-radius: 25px 10px 10px 0px
                            &__favorite-icon
                                left: 33px
                                img
                                    border-radius: 0 !important
                            .rating-wrap
                                display: flex
                                position: absolute
                                bottom: 18px
                                right: 17px
                            .house-info-name
                                display: block
                                position: absolute
                                bottom: 18px
                                left: 17px
                                h2
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 19px
                                    line-height: 22px
                                    color: #FFFFFF
                                &__distance
                                    span
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 13px
                                        color: #C4C4C4
                                    img
                                        height: 10px
                                        width: 9px
                                        border-radius: 0 !important
                            .house-info-cost
                                display: flex
                                align-items: center
                                justify-content: center
                                flex-direction: column
                                background: #000000
                                border-radius: 20px 0px 0px 20.5px
                                width: 118px
                                height: 41px
                                position: absolute
                                top: 60%
                                right: 0
                                p
                                    width: 100%
                                    text-align: center
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 13px
                                    color: #FFFFFF
                                .result-cost
                                    width: 100%
                                    text-align: center
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 10px
                                    color: #B3DA44

                        .house-info
                            display: none

            .map
                display: none

@media (max-width: 360px)
    .houseslist-navbar
        .Navbar
            .container
                width: 100%
                .liked-btn
                    right: 5%
                    display: flex
                    border: none
                .navbar-card-wrap
                    .form-group.adapt-hide
                        display: block
                    .first-group-wrap
                        width: 100%
                        display: flex
                        // justify-content: space-between
                        .form-group:first-child
                            margin-right: 10%
                        .form-group.guest
                            display: none
                            margin: 0
                        .form-group
                            width: 40%
                            input
                                width: 100%
                    .form-group:not(:first-child)
                        margin: 10px 0 0 0
                    .form-group
                        position: relative
                        margin: 0
                        width: 100%
                        label
                            font-size: 14px
                            display: block
                            width: auto
                            margin-bottom: 5px
                        input
                            width: 100px
                            height: 28px
                            background: #FFFFFF
                            box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 30px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 16px
                            color: #434242
                            margin: 0
                            padding-left: 7px
                        button
                            position: relative
                            margin: 0 0 0 auto
                            display: block
                            width: 150px
                            height: 28px
                            background: #000000
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                            border-radius: 35px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #FFFFFF
                    .second-group
                        display: inline-block
                        width: 100%
                        label
                            width: 40%
                            margin: 0 0 5px 0
                            float: left
                        label:first-child
                            margin-right: 10%
                        .DateRangePicker.DateRangePicker_1
                            margin: 0
                            width: 100%
    .main.houseslist
        margin: 0
        padding-left: 10px
        background: #000
        .container
            background: #fff
            flex-direction: column
            .main-titles
                height: 22px
                display: flex
                z-index: 111
                position: relative
                width: 100%
                display: flex
                padding: 0
                background: #000
                font-family: Roboto
                font-style: normal
                font-weight: 300
                font-size: 12px
                color: #C4C4C4
                justify-content: space-around
                li
                    width: 100%
                    margin: 0
                    display: flex
                    justify-content: center
                    align-items: center
                    height: 22px
                .lodging
                    background: #000000
                    border: 1px solid #DADADA
                    border-bottom: 0px
                    box-sizing: border-box
                    border-radius: 10px 180px 0px 0px
                    font-family: Roboto
                    font-style: normal
                    font-weight: 500
                    font-size: 12px
                    color: #E6FEA1
                    margin: 0
            .houses-list
                max-width: 576px
                width: 100%
                margin: 250px 0 0 0
