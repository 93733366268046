@mixin link-hover-before
    display: block
    content: ""
    position: absolute
    left: 9px
    top: 17px
    width: 0
    height: 0
    border-bottom: 8px solid #C4C4C4
    border-right: 5px solid transparent
    z-index: 1

@mixin link-hover-after
    content: attr(data-title)
    position: absolute
    left: 9px
    top: 24px
    width: 102px
    text-align: center
    border-radius: 0px 35px 35px 70px
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    z-index: 1
    background: #C4C4C4
    padding: 5px 10px

*
    padding: 0
    margin: 0

.container
    z-index: 2
    max-width: 1348px
    margin: 0 auto
    width: 100%
    position: relative
    .back-btn
        cursor: pointer
        border: none
        position: absolute
        width: 51px
        height: 36px
        background: #FFFDFD
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 35px
        justify-content: center
        display: flex
        left: 27px
        bottom: 21px
        img
            align-self: center
        :nth-child(2)
            display: none

.Navbar
    display: flex
    justify-content: flex-end
    background: #FFFFFF
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    height: 119px
    width: 100%
    .liked-btn
        position: absolute
        right: 5%
        display: none
        background: linear-gradient(180deg, #E1D2D2 23.44%, #F18D8D 100%), #434242
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 0px 35px 35px 35px
        align-items: center
        justify-content: center
        width: 38.7px
        height: 32px
    .container
        display: flex
        justify-content: space-between
        align-items: center
        .nav-logo
            position: absolute
            left: 0
            top: 0
            display: none
        .nav-links
            font-family: Roboto
            font-style: normal
            font-weight: 300
            font-size: 10px
            color: #E0FEF9
            display: flex
            display: none
            li
                list-style-type: none
            li:not(:first-child)
                margin-left: 10px
        .logo-wrap
            li
                list-style-type: none

        .navbar-card-wrap
            display: flex
            align-items: center
            background: #FFFDFD
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
            border-radius: 35px
            height: 41px
            justify-content: space-between
            padding: 0 34px
            width: 577px

            .form-group.town
                z-index: 999
                .DropDownContainer
                    position: relative
                    .DropDownHeader
                        width: 100%
                        border: none
                        outline: none
                        border: none
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 18px
                        color: #434242
                        cursor: pointer
                    .DropDownListContainer
                        width: 100%
                        position: absolute
                        z-index: 999
                        .DropDownList
                            margin-left: -10px
                            font-family: Roboto
                            font-style: normal
                            font-weight: 300
                            font-size: 16px
                            cursor: pointer
                            &:first-child
                                padding-top: 10px
                            .ListItem
                                padding-left: 10px
                                border-radius: 10px
                                color: #EFEEEE
                                background-color: #7A7A7A
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                list-style: none
                                margin-bottom: 2px

            .form-group.second-group
                width: 250px
            .first-group-wrap
                .form-group.guest
                    display: none
            .form-group
                width: 75px
                label
                    display: none
                    font-family: Roboto
                    font-style: normal
                    font-weight: 500
                    font-size: 11px
                    color: #434242
                &__btn
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 18px
                    color: #434242
                    background-color: Transparent
                    background-repeat: no-repeat
                    border: none
                    cursor: pointer
                    overflow: hidden

                input
                    width: 100%
                    border: none
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 18px
                    color: #434242

        .helpWrap
            color: white
            display: flex
            justify-content: flex-end
            align-items: center
            margin: 0

            .comment
                a
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 18px
                    color: #1B1818
                    text-decoration: none

            li
                list-style-type: none

            &__icons
                margin-left: 18px
                z-index: 0
                position: relative
                :nth-child(2)
                    display: none
            // including mixins
            .favorite-icon:hover::after, .notification-icon:hover::after, .post-icon:hover::after
                @include link-hover-after

            .favorite-icon:hover::before, .notification-icon:hover::before, .post-icon:hover::before
                @include link-hover-before

            .post-icon:hover::before, .post-icon:hover::after
                left: 8px !important

            .user-button
                position: relative
                margin-right: 18px
                margin-left: 47px
                &__btn
                    position: relative
                    display: flex
                    align-self: center
                    background-color: Transparent
                    background-repeat: no-repeat
                    border: none
                    cursor: pointer
                    overflow: hidden
                .user-wrap
                    display: flex
                    justify-content: center
                    width: 75px
                    height: 40px
                    border: 1px solid #000000
                    box-sizing: border-box
                    border-radius: 20px 0px 20px 20px
                    :nth-child(2)
                        display: none

                    .divIcon
                        width: 19px
                        margin-right: 7px

                    .userIcon
                        width: 28px
                        z-index: 111

@media (max-width: 768px)
    .Navbar
        max-width: 768px
        width: 100%
        background: #000
        position: relative
        box-sizing: border-box
        .container
            box-sizing: border-box
            position: relative
            width: 100%
            justify-content: flex-end
            .back-btn
                width: 38px
                height: 27px
                top: 7px
                left: 6px
                background: transparent
                border: 1px solid #FFFFFF
                box-sizing: border-box
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                border-radius: 35px
                :first-child
                    display: none
                :nth-child(2)
                    display: block
            .logo-wrap
                display: none
            .nav-logo
                position: inline
                display: block
                z-index: 0
                height: 71px
                overflow: hidden
                left: -10px
                img
                    overflow: hidden
            .nav-links
                display: flex
                position: absolute
                left: 51px
                top: 10px
            // .navbar-card-wrap
            //     display: none
            .helpWrap
                li:first-child
                    display: none
                &__icons
                    margin-left: 12px
                    a
                        :first-child
                            display: none
                        :nth-child(2)
                            display: block
                .user-button
                    margin-left: 23px
                    margin-right: 11px
                    .nav-menu
                        .nav-menu-list
                            li
                                display: block
                    &__btn
                        .user-wrap
                            display: flex
                            align-items: center
                            border: 1px solid #E5E5E5
                            box-sizing: border-box
                            border-radius: 20px 0px 35px 20px
                            width: 48px
                            height: 26px
                            :first-child
                                display: none
                            :nth-child(2)
                                display: block
                            .divIcon
                                width: 12px
                            .userIcon
                                width: 17px
                                height: 19px
