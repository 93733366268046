.Navbar
    .DateRangePicker.DateRangePicker_1
        height: 23px !important
        z-index: 111
        .DateRangePickerInput.DateRangePickerInput_1
            display: flex !important
            background: transparent
            margin-bottom: 7px

            .DateInput.DateInput_1
                padding: 0 !important
                width: 116px !important
                height: 23px !important
                font-family: Roboto !important
                font-style: normal !important
                font-weight: 300 !important
                font-size: 18px !important
                color: #969595 !important
                margin-right: 20px
                .DateInput_input.DateInput_input_1
                    padding: 0 !important
                    width: 116px !important
                    height: 23px !important

    .DateInput_fang.DateInput_fang_1
        display: none !important

    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1.CalendarMonthGrid_month__hidden.CalendarMonthGrid_month__hidden_2
        display: none !important
    .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2
        top: 30px !important
        background-color: #7A7A7A !important
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
        border-radius: 20px 0px 20px 20px !important
        width: 293px !important
        height: 136px !important
        .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3
            background-color: #7A7A7A !important
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
            border-radius: 20px 0px 20px 20px !important
            width: 293px !important
            height: 136px !important
            div
                div
                    width: auto !important
                    .DayPicker_focusRegion.DayPicker_focusRegion_1
                        background-color: #7A7A7A !important
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                        border-radius: 20px 0px 20px 20px !important
                        width: 293px !important
                        height: 136px !important
                        .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_leftButton__horizontalDefault.DayPickerNavigation_leftButton__horizontalDefault_5
                            padding: 0
                            width: auto !important
                            top: 13px
                            left: 13px
                            border: none
                            background: transparent
                            position: absolute
                        .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_rightButton__horizontalDefault.DayPickerNavigation_rightButton__horizontalDefault_5
                            padding: 0
                            width: auto !important
                            top: 13px
                            right: 13px
                            border: none
                            background: transparent
                            position: absolute
                        .DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2
                            background-color: #7A7A7A !important
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                            border-radius: 20px 0px 20px 20px !important
                            width: 293px !important
                            height: 136px !important
                            .CalendarMonthGrid.CalendarMonthGrid_1.CalendarMonthGrid__horizontal.CalendarMonthGrid__horizontal_2
                                background-color: #7A7A7A !important
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                                border-radius: 20px 0px 20px 20px !important
                                width: 293px !important
                                height: 136px !important
                                left: 0 !important
                                .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1:nth-child(2)
                                    .CalendarMonth.CalendarMonth_1
                                        .CalendarMonth_caption.CalendarMonth_caption_1
                                            position: relative !important
                                            width: 100% !important
                                            text-align: right
                                            strong
                                                margin-right: 1.5px !important
                                .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1
                                    background: #7A7A7A !important
                                    border-radius: 20px 0px 20px 20px !important
                                    width: 146.5px !important
                                    height: 136px !important
                                    border: none !important
                                    .CalendarMonth.CalendarMonth_1
                                        box-sizing: border-box !important
                                        width: 146.5px !important
                                        background: #7A7A7A !important
                                        padding: 13px 10.5px 25px 10.5px !important
                                        border-radius: 20px 0px 20px 20px !important
                                        border: none !important
                                        .CalendarMonth_caption.CalendarMonth_caption_1
                                            padding: 0
                                        .CalendarMonth_caption.CalendarMonth_caption_1
                                            text-align: left
                                            height: 12px
                                            font-size: 10px
                                            margin-bottom: 25px
                                            strong
                                                margin-left: 1.5px !important
                                                font-family: Roboto
                                                font-style: normal
                                                font-weight: normal
                                                font-size: 10px
                                                color: #E5E5E5
                                                text-transform: uppercase

    .CalendarMonth_table.CalendarMonth_table_1
        tbody
            tr
                height: 10px !important
                td
                    border: 1px solid #7A7A7A
                    width: 10px !important
                    height: 10px !important
                    border: none
                    font-family: Roboto !important
                    font-style: normal !important
                    font-weight: normal !important
                    font-size: 9px !important
                    color: #FFFDFD !important
                    background: transparent
    td
        border: 1px solid #7A7A7A !important
    td:hover
        border: 1px solid #BEDB60 !important
    .DayPicker_weekHeader.DayPicker_weekHeader_1
        top: 35px !important
        left: 3.5px !important
        padding: 0 !important
        font-size: 8px !important
        width: 149.5px !important
        .DayPicker_weekHeader_ul.DayPicker_weekHeader_ul_1
            li
                width: 17.5px !important
                color: #EFEEEE !important
                small
                    font-family: Roboto !important
                    font-style: normal !important
                    font-weight: normal !important
                    font-size: 8px !important
    .DayPicker_weekHeader.DayPicker_weekHeader_1:nth-child(2)
        left: 149.5px !important

    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1
        table
            margin-left: -3px !important
            border-spacing: 6px 1.5px !important
            border-collapse: separate !important
            box-sizing: border-box !important
            tbody
                td
                    margin: 3px 12px !important
                    box-sizing: content-box !important
                    border-radius: 20px !important
                    cursor: pointer

    .CalendarDay__selected_span
        z-index: 999 !important
        color: white !important
        border: 1px solid #BEDB60 !important

    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected
        z-index: 999 !important
        background: #a8a3a3 !important
        border: 1px solid #BEDB60 !important
        color: white

    // Will edit when hovered over. _span style also has this property
    .CalendarDay__selected:hover
        z-index: 999 !important
        background: #ce0c0c !important
        color: #ffffff !important

    // Will edit when the second date (end date) in a range of dates
    // is not yet selected. Edits the dates between your mouse and said date
    .CalendarDay__hovered_span:hover,.CalendarDay__hovered_span
        z-index: 999 !important
        background: #ce0c0c !important

    .DayPickerKeyboardShortcuts_buttonReset.DayPickerKeyboardShortcuts_buttonReset_1.DayPickerKeyboardShortcuts_show.DayPickerKeyboardShortcuts_show_2.DayPickerKeyboardShortcuts_show__bottomRight.DayPickerKeyboardShortcuts_show__bottomRight_3
        display: none
    .form-group-third
        height: 16px
        position: relative
        display: block
        .second-label-date
            margin-top: 7px !important

@media (min-width: 769px)
    .Navbar
        .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2
            top: 30px !important
            background-color: #7A7A7A !important
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
            border-radius: 20px 0px 20px 20px !important
            width: 500px !important
            height: 230px !important
            left: -100px !important
            .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3
                background-color: #7A7A7A !important
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                border-radius: 20px 0px 20px 20px !important
                width: 500px !important
                height: 230px !important
                div
                    div
                        width: auto !important
                        .DayPicker_focusRegion.DayPicker_focusRegion_1
                            background-color: #7A7A7A !important
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                            border-radius: 20px 0px 20px 20px !important
                            width: 500px !important
                            height: 230px !important
                            .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_leftButton__horizontalDefault.DayPickerNavigation_leftButton__horizontalDefault_5
                                padding: 0
                                width: auto !important
                                top: 13px
                                left: 13px
                                border: none
                                background: transparent
                                position: absolute
                            .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_rightButton__horizontalDefault.DayPickerNavigation_rightButton__horizontalDefault_5
                                padding: 0
                                width: auto !important
                                top: 13px
                                right: 13px
                                border: none
                                background: transparent
                                position: absolute
                            .DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2
                                background-color: #7A7A7A !important
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                                border-radius: 20px 0px 20px 20px !important
                                width: 500px !important
                                height: 230px !important
                                .CalendarMonthGrid.CalendarMonthGrid_1.CalendarMonthGrid__horizontal.CalendarMonthGrid__horizontal_2
                                    background-color: #7A7A7A !important
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
                                    border-radius: 20px 0px 20px 20px !important
                                    width: 500px !important
                                    height: 230px !important
                                    left: 0 !important
                                    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1:nth-child(2)
                                        .CalendarMonth.CalendarMonth_1
                                            .CalendarMonth_caption.CalendarMonth_caption_1
                                                position: relative !important
                                                width: 100% !important
                                                text-align: right
                                                strong
                                                    margin-right: 1.5px !important
                                    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1
                                        background: #7A7A7A !important
                                        border-radius: 20px 0px 20px 20px !important
                                        width: 250px !important
                                        height: 230px !important
                                        border: none !important
                                        .CalendarMonth.CalendarMonth_1
                                            box-sizing: border-box !important
                                            width: 250px !important
                                            background: #7A7A7A !important
                                            padding: 13px 10.5px 25px 10.5px !important
                                            border-radius: 20px 0px 20px 20px !important
                                            border: none !important
                                            .CalendarMonth_caption.CalendarMonth_caption_1
                                                padding: 0
                                            .CalendarMonth_caption.CalendarMonth_caption_1
                                                text-align: left
                                                height: 12px
                                                font-size: 10px
                                                margin-bottom: 25px
                                                strong
                                                    margin-left: 1.5px !important
                                                    font-family: Roboto
                                                    font-style: normal
                                                    font-weight: normal
                                                    font-size: 14px
                                                    color: #E5E5E5
                                                    text-transform: uppercase
            tbody
                tr
                    height: 10px !important
                    td
                        border: 1px solid #7A7A7A
                        width: 10px !important
                        height: 10px !important
                        border: none
                        font-family: Roboto !important
                        font-style: normal !important
                        font-weight: normal !important
                        font-size: 16px !important
                        color: #FFFDFD !important
                        background: transparent
                    .CalendarDay__blocked_out_of_range
                        color: #a8a3a3 !important
                        border: none !important
            .DayPicker_weekHeader.DayPicker_weekHeader_1
                top: 35px !important
                left: 4px !important
                padding: 0 !important
                font-size: 8px !important
                .DayPicker_weekHeader_ul.DayPicker_weekHeader_ul_1
                    li
                        width: 33px !important
                        color: #EFEEEE !important
                        small
                            font-family: Roboto !important
                            font-style: normal !important
                            font-weight: normal !important
                            font-size: 14px !important
            .DayPicker_weekHeader.DayPicker_weekHeader_1:nth-child(2)
                left: 254px !important

            .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1
                table
                    margin-left: -3px !important
                    border-spacing: 13px 4px !important
                    border-collapse: separate !important
                    box-sizing: border-box !important
                    tbody
                        td
                            margin: 3px 12px !important
                            box-sizing: content-box !important
                            border-radius: 20px !important
                            cursor: pointer
// @media (max-width: 576px)
//     .Navbar
//         .DateRangePicker.DateRangePicker_1
//             height: 23px !important
//             z-index: 111
//             position: relative
//             .DateRangePickerInput.DateRangePickerInput_1
//                 display: flex !important
//                 background: transparent
//                 margin-bottom: 7px
//                 position: relative
//                 z-index: 111

//                 .DateInput.DateInput_1
//                     padding: 0 !important
//                     width: 80px !important
//                     height: 23px !important
//                     font-family: Roboto !important
//                     font-style: normal !important
//                     font-weight: 300 !important
//                     font-size: 18px !important
//                     color: #969595 !important
//                     margin-right: 40px
//                     .DateInput_input.DateInput_input_1
//                         padding: 0 !important
//                         width: 80px !important
//                         height: 23px !important
//                         padding-left: 8px !important

//         .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2
//             z-index: 111
//             tbody
//                 tr
//                     td
//                     .CalendarDay__blocked_out_of_range
//                         color: #a8a3a3 !important
//                         border: none !important

@media (max-width: 576px)
    .Navbar
        .DateRangePicker.DateRangePicker_1
            height: 23px !important
            z-index: 111
            z-index: 1000
            position: inherit
            .DateRangePickerInput.DateRangePickerInput_1
                display: flex !important
                background: transparent
                margin-bottom: 7px
                position: relative
                z-index: 111

                .DateInput.DateInput_1
                    padding: 0 !important
                    width: 80px !important
                    height: 23px !important
                    font-family: Roboto !important
                    font-style: normal !important
                    font-weight: 300 !important
                    font-size: 18px !important
                    margin-right: 40px
                    .DateInput_input.DateInput_input_1
                        padding: 0 !important
                        width: 80px !important
                        height: 23px !important
                        padding-left: 8px !important

        .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2
            right: 0 !important
            width: calc(100vw - 10px) !important
            height: auto !important
            margin-left: 10px !important
            border-radius: 0 !important
            top: calc(71px + 150px) !important
            position: fixed !important
            .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3
                width: 100% !important
                height: auto !important
                border-radius: 0 !important
                div
                    border-radius: 0 !important
                    div
                        .DayPicker_weekHeaders.DayPicker_weekHeaders_1.DayPicker_weekHeaders__horizontal.DayPicker_weekHeaders__horizontal_2
                            display: flex !important
                            flex-direction: column !important
                            left: 0 !important
                            margin: 0 !important
                            .DayPicker_weekHeader.DayPicker_weekHeader_1
                                top: 0 !important
                                left: 0 !important
                                width: 100% !important
                                position: relative !important
                                ul.DayPicker_weekHeader_ul.DayPicker_weekHeader_ul_1
                                    padding: 13px 13px 25px 13px !important
                                    display: flex !important
                                    li.DayPicker_weekHeader_li.DayPicker_weekHeader_li_1
                                        width: 100% !important
                                        small
                                            font-size: 16px !important
                                        &:not(:last-child)
                                            margin-right: 1px !important
                                &:not(:nth-child(1))
                                    display: none !important
                        .DayPicker_focusRegion.DayPicker_focusRegion_1
                            width: 100% !important
                            height: auto !important

                            .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_leftButton__horizontalDefault.DayPickerNavigation_leftButton__horizontalDefault_5
                                padding: 0
                                width: auto !important
                                top: -20px
                                left: 13px
                                border: none
                                background: transparent
                                position: absolute
                                svg
                                    width: 30px !important
                                    fill: #d3dbdd !important
                            .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__default.DayPickerNavigation_button__default_2.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_3.DayPickerNavigation_button__horizontalDefault.DayPickerNavigation_button__horizontalDefault_4.DayPickerNavigation_rightButton__horizontalDefault.DayPickerNavigation_rightButton__horizontalDefault_5
                                padding: 0
                                width: auto !important
                                top: -20px
                                right: 13px
                                border: none
                                background: transparent
                                position: absolute
                                svg
                                    width: 30px !important
                                    fill: #d3dbdd !important

                            .DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2
                                width: 100% !important
                                height: auto !important
                                height: calc(100vh - 71px - 150px - 60px) !important
                                position: relative
                                overflow: hidden auto !important
                                border-radius: 0 !important
                                .CalendarMonthGrid.CalendarMonthGrid_1.CalendarMonthGrid__horizontal.CalendarMonthGrid__horizontal_2
                                    width: 100% !important
                                    display: flex !important
                                    flex-direction: column !important
                                    height: auto !important
                                    position: relative

                                    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1.CalendarMonthGrid_month__hideForAnimation.CalendarMonthGrid_month__hideForAnimation_2.CalendarMonthGrid_month__hidden.CalendarMonthGrid_month__hidden_3
                                        display: none !important
                                    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1.CalendarMonthGrid_month__hidden.CalendarMonthGrid_month__hidden_2
                                        display: none !important
                                    .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1
                                        height: auto !important
                                        width: 100% !important
                                        .CalendarMonth.CalendarMonth_1
                                            width: 100% !important
                                            padding: 13px 13px 55px 13px !important
                                            border-radius: 0 !important
                                            .CalendarMonth_caption.CalendarMonth_caption_1
                                                text-align: center !important
                                                strong
                                                    font-size: 16px !important
                                            table.CalendarMonth_table.CalendarMonth_table_1
                                                table-layout: fixed !important
                                                width: 100%
                                                margin: 0 !important
                                                height: auto !important
                                                border-spacing: 1px !important
                                                tbody
                                                    display: table-row-group
                                                    height: auto !important
                                                    tr
                                                        height: auto !important
                                                        td
                                                            border-radius: 100px !important
                                                            height: calc((100vw - 26px - 7px) / 7) !important
                                                            font-size: 16px !important
                                                        .CalendarDay__blocked_out_of_range
                                                            color: #a8a3a3 !important
                                                            border: 1px solid #7A7A7A !important
@media (min-width: 577px) and (max-width: 768px)
    .Navbar
        .DateRangePicker.DateRangePicker_1
            height: 23px !important
            z-index: 111
            .DateRangePickerInput.DateRangePickerInput_1
                display: flex !important
                background: transparent
                margin-bottom: 7px
                text-align: left !important
                .DateInput.DateInput_1
                    padding: 0 !important
                    width: 75px !important
                    height: 23px !important
                    font-family: Roboto !important
                    font-style: normal !important
                    font-weight: 300 !important
                    font-size: 18px !important
                    color: #969595 !important
                    text-align: left !important
                    margin-left: 0px !important
                    margin-right: 30px
                    .DateInput_input.DateInput_input_1
                        padding: 0 !important
                        width: 75px !important
                        height: 23px !important
@media (max-width: 576px)
    .Navbar
        .DateRangePicker.DateRangePicker_1
            height: 28px !important
            z-index: 111
            .DateRangePickerInput.DateRangePickerInput_1
                display: block !important
                background: transparent
                margin-bottom: 7px
                text-align: left !important
                .DateInput.DateInput_1
                    padding: 0 !important
                    width: 40% !important
                    height: 28px !important
                    font-family: Roboto !important
                    font-style: normal !important
                    font-weight: 300 !important
                    font-size: 18px !important
                    color: #969595 !important
                    text-align: left !important
                    margin-left: 0px !important
                    margin-right: 10%
                    .DateInput_input.DateInput_input_1
                        padding: 0 !important
                        width: 100% !important
                        height: 28px !important
                        padding-left: 7px !important
            tbody
                tr
                    td
                        .CalendarDay__blocked_out_of_range
                            color: #a8a3a3 !important
                            border: none !important
