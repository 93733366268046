.price-calculate-form
    position: relative
    display: flex
    flex-direction: column
    width: 295px
    height: auto
    background: #FFFDFD
    border: 1px solid #CFEE75
    box-sizing: border-box
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 35px
    header
        display: flex
        width: 100%
        position: relative
        height: 39px
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
        border-radius: 35px 35px 0px 0px
        align-items: center
        h2
            margin-left: 32px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 18px
            color: #1B1818
    main
        padding: 18px 23px 16px 27px
        height: auto
        .form-group-conditions
            &__header
                h3
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                    color: #1B1818
            &__content
                margin-top: 10px
                display: flex
                align-items: center
                flex-direction: column
                // justify-content: space-between
                .conditions-item
                    margin-top: 5px
                    width: 100%
                    display: flex
                    justify-content: space-between
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                    color: #1B1818
                    .arrive-date,.people-count
                        width: 70px
                        text-align: left
                        color: #434242

        .form-group-rent
            margin-top: 15px
            &__header
                h3
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                    color: #1B1818
            &__content
                margin-top: 10px
                display: flex
                align-items: center
                justify-content: space-between
                .reserv-day
                    display: flex
                    align-items: center
                    &__btn
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 10px
                        color: #FFFFFF
                        background: #434242
                        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25)
                        border-radius: 35px
                        border: none
                        width: 54px
                        height: 14px
                        cursor: pointer
                        img
                            margin-left: 5px
                .price-per-day
                    margin-left: 11px
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 10px
                        color: #7A7A7A
                .price-for-days
                    margin-left: 15px
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 15px
                        color: #434242
        .form-group-services
            margin-top: 15px
            &__header
                h3
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 13px
                    color: #1B1818
            &__content::-webkit-scrollbar
                width: 5px
                height: 2px /* высота для горизонтального скролла */
                background-color: Transparent
            &__content::-webkit-scrollbar-thumb
                width: 5px
                height: 5px
                background: #C4C4C4
                border-radius: 5px
            &__content
                height: 388px
                overflow: auto

                .service-item-wrap
                    &__name
                        margin-top: 20px
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 10px
                        color: #1B1818
                    .service-list
                        display: flex
                        justify-content: space-between
                        align-items: center
                        height: 14px
                        margin-top: 7px
                        &__name
                            p
                                width: 126px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 10px
                                color: #7A7A7A
                        &__price
                            p
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 10px
                                color: #7A7A7A
                        .switch-box.active
                            .react-switch
                                .react-switch-bg
                                    display: flex !important
                                    align-items: center !important
                                    border: 2px solid#BEDB60 !important
                                    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25) !important
                                .react-switch-handle
                                    top: 3px !important
                                    left: 2px
                        .switch-box.passive
                            .react-switch
                                .react-switch-bg
                                    display: flex !important
                                    align-items: center !important
                                    border: 2px solid#969595 !important
                                    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25) !important
                                .react-switch-handle
                                    top: 3px !important
                                    left: 2px
                    .service-item-price
                        margin-top: 18px
                        display: flex
                        align-items: center
                        justify-content: flex-end
                        p
                            width: 100px
                            text-align: start
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 15px
                            color: #434242
    footer
        background: #FFFDFD
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
        border-radius: 0px 0px 35px 35px
        height: 42px
        position: relative
        .result-price
            height: 100%
            position: relative
            display: flex
            align-items: center
            justify-content: flex-end
            p
                margin-right: 30px
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 20px
                color: #000000

@media (max-width: 768px)
