section.table_section
    width: 100%
    display: block
    position: relative
    padding: 20px
    height: auto
    box-sizing: border-box
    background-color: #fff
    .tableTitle
        display: flex
        justify-content: space-between
        &__left-side
            input
                margin-right: 20px
                border: none
                outline: none
                font-family: "Roboto"
                color: black
                font-size: 18px
                text-align: left
                font-weight: 600
                text-align: center
                background: transparent
            button
                margin-right: 20px
                box-sizing: border-box
                padding: 10px 16px
                background: rgba(0, 76, 204, 0.1)
                border-radius: 6px
                border: none
                outline: none
                font-style: normal
                font-weight: bold
                font-size: 14px
                color: #004CCC
                box-sizing: border-box
                cursor: pointer
    .table-wrapper
        width: 100%
        position: relative
        max-height: 600px
        // width: calc(100vw - 250px - 40px - 20px)
        overflow: auto
        margin-top: 20px
        background: white
        table.manageCalendar_table:focus
            box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5)
            outline: 0
        table.manageCalendar_table
            border: none
            border-collapse: separate
            border-spacing: 0
            table-layout: fixed
            border: 1px solid black
            thead, tbody
                tr
                    th.first_column
                        width: auto
                        // position: relative
                        text-overflow: ellipsis
                        white-space: nowrap
            thead
                tr
                    th
                        background: white
                    th.day_number
                        border: 2px solid black
                        padding: 3px
                        position: sticky
                        top: 0
                        z-index: 1
                        width: 30px
                        background: white
                    th.first_column
                        position: sticky
                        left: 0
                        top: 0
                        z-index: 4
                        border: 2px solid black
            tbody
                tr
                    th
                        border: 2px solid black
                        position: sticky
                        left: 0
                        background: white
                        z-index: 1
                    td
                        cursor: pointer
                        min-width: 30px
                        width: 30px
                        height: 30px
                        border-right: 1px solid #aaa5a5
                        border-bottom: 1px solid #aaa5a5
                        input
                            font-size: 10px
                            // background: transparent
                            border: none
                            outline: none
                            width: 100%
                            height: 100%
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button
                            -webkit-appearance: none
                            margin: 0
                        input[type=number]
                            -moz-appearance: textfield

                    input.white
                        z-index: 1
                        background-color: white
                    input.white.black,td.saturday.white.black
                        background-color: black
                        color: white
                    input.white.black.green,td.saturday.white.black.green
                        background-color: green
                        color: red
                    input.saturday
                        background-color: rgba(0, 76, 204, 0.1)

@media (max-width: 576px)
    section.table_section
        .tableTitle
            display: flex
            flex-direction: column-reverse
            &__left-side
                display: flex
                button
                    padding: 10px
                    margin: 0
                input
                    margin: 0
                    width: 100px
            &__right-side
                h2
                    text-align: right
        .table-wrapper
            width: 100%
            position: relative
            max-height: 600px
            overflow: auto
            margin-top: 20px
            background: white
            table.manageCalendar_table:focus
                box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5)
                outline: 0
            table.manageCalendar_table
                border: none
                border-collapse: separate
                border-spacing: 0
                table-layout: fixed
                border: 1px solid black
                thead, tbody
                    tr
                        th.first_column
                            width: 150px
                            font-size: 12px
                            width: auto
                            // position: relative
                            text-overflow: ellipsis
                            white-space: normal
                thead
                    tr
                        th
                            background: white
                        th.day_number
                            border: 2px solid black
                            padding: 3px
                            position: sticky
                            top: 0
                            z-index: 1
                            width: 20px
                            font-size: 12px
                            background: white
                        th.first_column
                            position: sticky
                            left: 0
                            top: 0
                            z-index: 4
                            border: 2px solid black
                tbody
                    tr
                        th
                            border: 2px solid black
                            position: sticky
                            left: 0
                            background: white
                            z-index: 1
                        td
                            cursor: pointer
                            min-width: 20px
                            width: 20px
                            height: 20px
                            border-right: 1px solid #aaa5a5
                            border-bottom: 1px solid #aaa5a5
                            input
                                font-size: 7px
                                // background: transparent
                                border: none
                                outline: none
                                width: 100%
                                height: 100%
                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button
                                -webkit-appearance: none
                                margin: 0
                            input[type=number]
                                -moz-appearance: textfield

                        input.white
                            z-index: 1
                            background-color: white
                        input.white.black,td.saturday.white.black
                            background-color: black
                            color: white
                        input.white.black.green,td.saturday.white.black.green
                            background-color: green
                            color: red
                        input.saturday
                            background-color: rgba(0, 76, 204, 0.1)
