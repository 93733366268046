.main-modal
    background: rgba(0,0,0,0.8)
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    z-index: 999
    .modal-content
        border-radius: 10px
        position: relative
        padding: 5px
        display: flex
        button.exit-btn
            cursor: pointer
            z-index: 1
            position: absolute
            top: 15px
            right: 15px
            background: transparent
            color: white
            width: 20px
            height: 20px
            border: none
            outline: none
            img
                width: 100%
                height: 100%
