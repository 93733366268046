.date-picker-form
    position: relative
    width: 392px
    background: #FFFDFD
    border: 1px solid #BEDB60
    box-sizing: border-box
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 20px 20px 0px 20px
    .year-info
        position: absolute
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 16px
        color: #CBCBCB
        transform: rotate(-90deg)
    .year-info.left
        left: 0px
        bottom: 20px
    .year-info.right
        right: 0px
        top: 20px
    header
        position: relative
        margin-top: 22px
        display: flex
        justify-content: center
        :not(:last-child)
            margin-right: 15px
        :not(:nth-child(2))
            border: none
            outline: none
            background: transparent
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 10px
            color: #969595
            text-transform: uppercase
            cursor: pointer
        :nth-child(2)
            border: none
            outline: none
            background: transparent
            font-family: Roboto
            font-style: normal
            font-weight: bold
            font-size: 10px
            color: #000000
            text-transform: uppercase
    .calendar
        display: flex
        margin-top: 14px
        table
            margin-left: 30px
            margin-bottom: 22px
            border-spacing: 10px 3px
            thead
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 8px
                color: #000000
                height: 10px !important
                tr
                    height: 10px !important
                    :not(:last-child)
                        margin-right: 10px
                    td
                        width: 15px !important
            tbody
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 8px
                color: #000000
                tr
                    height: 15px
                    td
                        text-align: center !important
                        width: 15px !important
                        border-radius: 15px
                    .day
                        width: 15px !important
                    .day.counted
                        border: 1px solid #BEDB60
                        box-sizing: border-box
                        border-radius: 20px
                    .day.privated
                        box-sizing: border-box
                        border-radius: 20px
                        border: 1px solid red
                        background: rgba(223, 55, 13, 0.425)
                    .day:hover
                        background-color: #eaeaea
                        cursor: pointer
                    .day.selected
                        background: lightgray
                    // .day.today
                    //     background-color: dodgerblue
                    //     color: white
                    .day.today
                        background-color: #f1855a
                    .day.today.selected
                        background-color: #4e7094

        .second-content
            margin-left: 25px
            // display: flex
            // flex-direction: column
            .low-price
                h2
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 10px
                    color: #434242
                ul
                    margin-top: 8px
                    display: flex
                    li
                        display: flex
                        justify-content: center
                        align-items: center
                        width: 12px
                        height: 12px
                        background: rgba(6, 255, 136, 0.2)
                        border-radius: 12px
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 8px
                        color: #000000
                        list-style-type: none
                    :not(:last-child)
                        margin-right: 2px
            .designations-wrap
                margin-top: 7px
                display: flex
                flex-direction: column
                position: relative
                &__item
                    margin-top: 1px
                    display: flex
                    justify-content: start
                    .designation
                        width: 12px
                        height: 12px
                        border-radius: 12px
                    p
                        display: flex
                        align-items: center
                        margin-left: 4px
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 10px
                        color: #434242
                        width: 100px
                    .designation.booked
                        width: 11px
                        height: 11px
                        border: 1px solid red
                        background: rgba(223, 55, 13, 0.425)
                    .designation.free
                        background: #CDFDE6
                    .designation.your
                        border: 1px solid #BEDB60
                        box-sizing: border-box
                        border-radius: 20px
                    .designation.impossible
                        background: #ECECEC
    .change-date
        display: flex
        justify-content: flex-end
        margin-top: 8px
        position: absolute
        right: 15px
        bottom: 8px
        &__btn
            cursor: pointer
            width: 101px
            height: 20px
            background: transparent
            border: 1px solid #BEDB60
            box-sizing: border-box
            border-radius: 20px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 11px
            color: #434242

@media (max-width: 426px)
    .date-picker-form
        position: relative
        width: 234px
        background: #FFFDFD
        border: 1px solid #BEDB60
        box-sizing: border-box
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 20px 20px 0px 20px
        .year-info
            position: absolute
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 16px
            color: #CBCBCB
            transform: rotate(-90deg)
        .year-info.left
            left: 0px
            bottom: 20px
        .year-info.right
            right: 0px
            top: 20px
        header
            position: relative
            margin-top: 22px
            display: flex
            justify-content: center
            :not(:last-child)
                margin-right: 15px
            :not(:nth-child(2))
                border: none
                outline: none
                background: transparent
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 10px
                color: #969595
                text-transform: uppercase
                cursor: pointer
            :nth-child(2)
                border: none
                outline: none
                background: transparent
                font-family: Roboto
                font-style: normal
                font-weight: bold
                font-size: 10px
                color: #000000
                text-transform: uppercase
        .calendar
            display: flex
            margin-top: 14px
            table
                margin-left: 30px
                margin-bottom: 22px
                border-spacing: 10px 3px
                thead
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 8px
                    color: #000000
                    height: 10px !important
                    tr
                        height: 10px !important
                        :not(:last-child)
                            margin-right: 10px
                        td
                            width: 15px !important
                tbody
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 8px
                    color: #000000
                    tr
                        height: 15px
                        td
                            text-align: center !important
                            width: 15px !important
                            border-radius: 15px
                        .day
                            width: 15px !important
                        .day:hover
                            background-color: #eaeaea
                            cursor: pointer
                        .day.selected
                            background: lightgray
                        // .day.today
                        //     background-color: dodgerblue
                        //     color: white
                        .day.today:hover
                            background-color: #0081ff
                        .day.today.selected
                            background-color: #4e7094
    .additional-wrap-mob
        margin-top: 21px
        position: relative
        width: 100%
        .second-content
            margin-left: 25px
            // display: flex
            // flex-direction: column
            .designations-wrap
                margin-top: 7px
                display: flex
                flex-direction: column
                position: relative
                &__item
                    margin-top: 1px
                    display: flex
                    justify-content: start
                    .designation
                        width: 12px
                        height: 12px
                        border-radius: 100px
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 10px
                        color: #434242
                        margin-left: 4px
                        width: 140px
                    .designation.booked
                        width: 11px
                        height: 11px
                        border: 1px solid red
                        background: rgba(223, 55, 13, 0.425)
                    .designation.free
                        background: #CDFDE6
                    .designation.your
                        border: 1px solid #BEDB60
                        box-sizing: border-box
                        border-radius: 20px
                    .designation.impossible
                        background: #ECECEC
        .change-date
            display: flex
            justify-content: flex-end
            margin-top: 25px
            position: relative
            right: 15px
            bottom: 8px
            &__btn
                cursor: pointer
                width: 101px
                height: 20px
                background: transparent
                border: 1px solid #BEDB60
                box-sizing: border-box
                border-radius: 20px
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 11px
                color: #434242
