@keyframes slideMainOff
    0%
        margin-left: 250px
    100%
        margin-left: 0px

@keyframes slideMainOn
    0%
        margin-left: 0px
    100%
        margin-left: 250px

@keyframes tableWidthOff
    0%
        width: calc(100vw - 250px - 40px - 20px) !important
    100%
        width: calc(100vw - 40px - 20px) !important

@keyframes tableWidthOn
    0%
        width: calc(100vw - 40px - 20px) !important
    100%
        width: calc(100vw - 250px - 40px - 20px) !important

// for mobile 

.manageCalendar
    background-color: #E5E5E5
    width: 100%
    height: 100vh
    font-family: Roboto
    .content
        display: flex
        height: calc(100% - 54px)
        main.calendarMain.passive
            animation: slideMainOff 0.3s linear forwards
            .table-wrapper
                animation: tableWidthOff 0.3s linear forwards
        main.calendarMain.active
            animation: slideMainOn 0.3s linear forwards
            .table-wrapper
                animation: tableWidthOn 0.3s linear forwards
        main.calendarMain
            height: 100%
            margin-left: 250px
            position: relative
            width: 100%
            padding: 10px
            box-sizing: border-box
            overflow: auto
            .title
                &__header
                    h1
                        font-family: 'Roboto'
                        color: black
                        font-size: 32px
                        text-align: left
                &__subheader
                    h4
                        font-family: 'Roboto'
                        color: #aaa5a5
                        font-size: 16px
                        text-align: left
                        font-weight: normal
            .searchParameters_wrap
                display: flex
                flex-direction: column
                box-sizing: border-box
                width: 100%
                section.searchParams
                    margin-top: 20px
                    background: white
                    border-radius: 20px
                    padding: 15px
                    header.searchParams__title
                        border-bottom: 1px solid rgba(104, 125, 141, 0.1)
                        padding-bottom: 12px
                        h3
                            font-family: 'Roboto'
                            color: black
                            font-size: 18px
                            text-align: left
                            font-weight: 500
                section.searchParams
                    .searchParams__content
                        padding-top: 12px
                section.searchParams.town_setting
                    width: 200px
                    .searchParams__content
                        display: flex
                        span
                            font-family: 'Roboto'
                            color: black
                            font-size: 16px
                            text-align: left
                            font-weight: 400
                        .DropDownContainer
                            width: 75px
                            position: relative
                            margin-left: 20px
                            .DropDownHeader:before
                                content: ""
                                position: absolute
                                right: 0px
                                top: 10px
                                width: 0
                                height: 0
                                border-left: 5px solid transparent
                                border-right: 5px solid transparent
                                border-top: 5px solid #969595
                            .DropDownHeader
                                width: 100%
                                border: none
                                outline: none
                                font-family: Roboto
                                font-style: normal
                                font-weight: 400
                                font-size: 16px
                                color: #969595
                                cursor: pointer
                            .DropDownListContainer
                                width: 100%
                                position: absolute
                                z-index: 999
                                .DropDownList
                                    margin-right: -10px
                                    margin-left: -10px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: 300
                                    font-size: 16px
                                    cursor: pointer
                                    &:first-child
                                        padding-top: 10px
                                    .ListItem
                                        padding-left: 10px
                                        border-radius: 10px
                                        color: #EFEEEE
                                        background-color: #7A7A7A
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                        list-style: none
                                        margin-bottom: 2px
                section.searchParams.synonymSearch_setting
                    max-width: 500px
                    width: 100%
                    box-sizing: border-box
                    position: relative
                    .searchParams__content.searchBySynonym
                        display: flex
                        width: 100%
                        input.searchBySynonym__input
                            border-radius: 6px
                            padding: 12px 12px 12px 16px
                            background: #F8FBFC
                            font-style: normal
                            font-weight: 500
                            font-size: 16px
                            border: none
                            outline: none
                            width: 100%
                        button.searchBySynonym__button
                            padding: 10px 16px
                            background: rgba(0, 76, 204, 0.1)
                            border-radius: 6px
                            border: none
                            outline: none
                            font-style: normal
                            font-weight: bold
                            font-size: 14px
                            width: 100px
                            color: #004CCC
                            margin-left: 10px
                            box-sizing: border-box
                            cursor: pointer
                        input.searchBySynonym__input::-webkit-input-placeholder
                            color: rgba(104, 125, 141, 0.5)
                        input.searchBySynonym__input::-moz-placeholder
                            color: rgba(104, 125, 141, 0.5)
                        input.searchBySynonym__input:-moz-placeholder
                            color: rgba(104, 125, 141, 0.5)
                        input.searchBySynonym__input:-ms-input-placeholder
                            color: rgba(104, 125, 141, 0.5)

                section.advancedSearch_setting
                    box-sizing: border-box
                    margin-bottom: 20px
                    position: relative
                    max-width: 700px
                    width: 100%
                    .searchParams__content
                        .manageCalendarSearchFilters
                            position: relative
                            .datesCostsWrapper
                                display: flex
                                justify-content: space-between
                                .costInterval
                                    margin-left: 30px
                                .arriveAway_settings,.costInterval
                                    display: flex
                                    flex-direction: column
                                    width: 100%
                                    position: relative
                                    .inptWrapper:not(:first-child)
                                        margin-top: 10px
                                    .inptWrapper
                                        display: flex
                                        align-items: center
                                        .prompt
                                            width: 120px
                                            span
                                                font-family: 'Roboto'
                                                color: black
                                                font-size: 16px
                                                text-align: left
                                                font-weight: 400
                                        input
                                            border-radius: 6px
                                            padding: 12px 12px 12px 16px
                                            background: #F8FBFC
                                            font-style: normal
                                            font-weight: 500
                                            font-size: 16px
                                            border: none
                                            outline: none
                                            width: calc(100% - 120px)
                            button.filters_search_button
                                z-index: 999
                                padding: 10px 16px
                                background: rgba(0, 76, 204, 0.1)
                                border-radius: 6px
                                border: none
                                outline: none
                                font-style: normal
                                font-weight: bold
                                font-size: 14px
                                width: 100px
                                color: #004CCC
                                box-sizing: border-box
                                position: absolute
                                cursor: pointer
                                right: 0
                                bottom: 0
                            .parametrs
                                .parametrs_button
                                    z-index: 999
                                    padding: 10px 16px
                                    background: rgba(0, 76, 204, 0.1)
                                    border-radius: 6px
                                    border: none
                                    outline: none
                                    font-style: normal
                                    font-weight: bold
                                    font-size: 14px
                                    width: 150px
                                    color: #004CCC
                                    box-sizing: border-box
                                    cursor: pointer
            section.table_section
                border-radius: 20px

@media (max-width: 576px)
    .manageCalendar
        .content
            aside.left-sidebar
                z-index: 2
            main.calendarMain.passive
                animation: none
            .table-wrapper
                animation: none
            main.calendarMain.active
                animation: none
            .table-wrapper
                animation: none
            main.calendarMain
                margin: 0
                z-index: 1
                .searchParameters_wrap
                    section.searchParams.advancedSearch_setting
                        .searchParams__content
                            form.manageCalendarSearchFilters
                                .datesCostsWrapper
                                    flex-direction: column
                                    .costInterval
                                        margin: 0
