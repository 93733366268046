.main-preloader
    display: flex
    width: 100vw
    height: 100vh
    position: relative
    align-items: center
    justify-content: center
.house-page-navbar
    position: fixed
    display: block
    top: 0
    width: 100%
    z-index: 888
.housepage-wrap
    .manager-contact-wrapper
        margin-top: 30px
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-around
        align-items: center
        width: 295px
        height: auto
        background: #FFFDFD
        border: 1px solid #CFEE75
        box-sizing: border-box
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 35px
        padding: 18px 23px 16px 27px
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 24px
        color: #1B1818
        a
            font-size: 22px
    .container
        max-width: 1348px
        width: 100%
        .main-titles
            display: none

        .main.house-page
            margin-top: 119px + 35px
            margin-bottom: 35px
            position: relative
            display: flex
            justify-content: space-around
            .house-main
                position: relative
                display: flex
                flex-direction: column
                width: 490px
                &__name
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 30px
                    color: #1B1818

                &__location
                    display: flex
                    align-items: center
                    margin-top: 16px
                    span
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 18px
                        color: #969595
                    img
                        margin-left: 5px

                &__image
                    position: relative
                    margin-top: 22px
                    height: auto
                    width: 100%
                    .slide-image-wrap
                        position: relative
                        height: auto
                        width: 100%
                        overflow: hidden
                        outline: none
                        .slick-slider
                            position: relative
                            height: 100%
                            width: 100%
                            .slick-list
                                height: 100%
                                width: 100%
                                .slick-track
                                    height: 100%
                                    display: flex
                                    div
                                        height: 100%
                                        outline: none
                        .prewClick,.nextClick
                            z-index: 111
                            cursor: pointer
                            height: 25px
                            width: 15px
                            background: Transparent
                            border: none
                            outline: none
                        button
                            background: Transparent
                            border: none
                            outline: none
                            width: 100%
                            height: 100%
                            img
                                width: 100%
                                height: 100%
                        img
                            width: 100%
                            height: 320px
                            cursor: -webkit-zoom-in
                            cursor: zoom-in
                        .prewClick
                            position: absolute
                            left: 25px
                            bottom: 43%
                            img
                                cursor: pointer
                        .nextClick
                            cursor: pointer
                            position: absolute
                            right: 25px
                            bottom: 43%
                            img
                                cursor: pointer
                    .swap-btn
                        position: absolute
                        background: #000000
                        border: 1px solid #CFEE75
                        box-sizing: border-box
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 35px
                        width: 39px
                        height: 29px
                        left: 30px
                        bottom: 40px
                        z-index: 1
                        img
                            width: 20px
                            height: 20px
                    .mob-map,.mob-img
                        width: 100%
                        height: auto
                        img
                            max-height: 100vh
                            height: 100%
                            width: 100%
                    img
                        border-radius: 35px
                        width: 100%
                    &__video-icon
                        position: absolute
                        width: 39px
                        height: 29px
                        top: 30px
                        right: 30px
                        border: none
                        background-color: Transparent
                        background-repeat: no-repeat
                        cursor: pointer
                &__map
                    margin-top: 50px
                    height: 400px

            .second-column
                width: 392px
                .house-description
                    &__descr
                        width: 290px
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            padding-right: 5px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 13px
                            color: #434242
                            margin-top: 10px
                            height: 200px
                            overflow: auto
                            p
                                text-align: justify
                        &__content::-webkit-scrollbar
                            width: 5px
                            height: 2px /* высота для горизонтального скролла */
                            background-color: Transparent
                        &__content::-webkit-scrollbar-thumb
                            width: 5px
                            height: 5px
                            background: #C4C4C4
                            border-radius: 5px
                            p
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 13px
                                color: #434242

                    &__condition
                        margin-top: 14px
                        width: 290px
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content::-webkit-scrollbar
                            width: 5px
                            height: 2px /* высота для горизонтального скролла */
                            background-color: Transparent
                        &__content::-webkit-scrollbar-thumb
                            width: 5px
                            height: 5px
                            background: #C4C4C4
                            border-radius: 5px
                        &__content
                            margin-top: 10px
                            height: 250px
                            overflow: auto
                            .short-info
                                p
                                    display: flex
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 13px
                                    color: #434242
                                    align-items: center

                                    img
                                        margin-left: 5px
                                    .second-bed
                                        margin-left: 1px
                            .additional-info
                                margin-top: 13px
                                &__wrap
                                    margin-top: 5px
                                    display: flex
                                    align-items: center
                                    .info-icon
                                        width: 20px
                                    p
                                        // margin-left: 9px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 13px
                                        color: #434242
                    &__special-conditions
                        margin-top: 14px
                        width: 290px
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            margin-top: 10px
                            .special-conditions-info
                                margin-top: 13px
                                &__wrap
                                    display: flex
                                    align-items: center
                                    .info-icon
                                        width: 20px
                                    p
                                        margin-left: 9px
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 13px
                                        color: #434242
                    &__date
                        margin-top: 20px
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            margin-top: 15px
                            .prompt
                                margin-left: 14px
                                margin-top: 14px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 9px
                                color: #969595
                    &__faq
                        margin-top: 23px
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            margin-top: 15px
                            ul
                                li
                                    margin-top: 5px
                                    list-style-type: none
                                    a
                                        text-decoration: none
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 13px
                                        color: #1B1818

            .third-column
                width: 295px
                display: flex
                flex-direction: column
                justify-content: flex-start
                .still-question
                    display: flex
                    align-items: center
                    justify-content: flex-end
                    margin-bottom: 39px
                    p
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 18px
                        color: #969595
                    &__btn
                        margin-left: 6px
                        width: 67px
                        height: 35px
                        background: #FFFDFD
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 30px 30px 0px 30px
                        border: none
                .phoneError
                    width: 293px
                    margin-top: 21px
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    p
                        text-align: center
                        font-size: 20px
                        color: red
                .phone-input
                    margin-top: 21px
                    display: flex
                    justify-content: center
                    input
                        cursor: pointer
                        padding-left: 40px
                        width: 293px
                        height: 49px
                        background: Transparent
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 35px
                        border: none
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 24px
                        color: black
                        box-sizing: border-box
                .after-success-order
                    margin-top: 21px
                    width: 293px
                    text-align: center
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 24px
                    color: green
                .not-blame
                    margin-top: 21px
                    width: 293px
                    text-align: justify
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 14px
                    color: #9e9898
                    p
                        text-align: center
                .reservation
                    margin-top: 21px
                    display: flex
                    justify-content: center
                    &__btn
                        cursor: pointer
                        width: 293px
                        height: 49px
                        background: #434242
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 35px
                        border: none
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 24px
                        color: #FFFDFD
    .ymaps-b-balloon.ymaps-i-custom-scroll
        .ymaps-b-balloon__content
            a
                text-decoration: none
                color: blue
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 14px
@media (min-width: 992px) and (max-width: 1200px)
    .housepage-wrap
        box-sizing: border-box
        position: relative
        .house-page-navbar
            position: relative
            .Navbar
                box-sizing: border-box
                position: relative
                max-width: 1200px
                width: 100%
                .container
                    max-width: 1200px
                    width: 100%
                    .navbar-card-wrap
                        display: none
    .housepage-wrap
        .container
            max-width: 1200px
            width: 100%
            .main-titles
                display: none
            .main.house-page
                margin-top: 50px
                margin-bottom: 35px
                position: relative
                display: flex
                justify-content: space-around
                .house-main.first-column
                    max-width: 490px
                    width: 100%
                    .house-description__date
                        margin-top: 30px
                        display: flex
                        flex-direction: column
                        align-items: flex-end
                        &__header
                            width: 392px
                            display: inline
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            margin-top: 15px

                .house-main.third-column
                    display: none
                .house-main.second-column
                    .house-description
                        &__date
                            display: none
                        &__faq
                            width: 295px
                            text-align: center
                        .mob-price-calc
                            margin-top: 30px
                            .price-calculate-form
                                margin: 0 0
                            .phoneError
                                width: 293px
                                margin-top: 21px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                p
                                    text-align: center
                                    font-size: 20px
                                    color: red
                            .phone-input
                                width: 295px
                                margin: 30px 0 0 0
                                display: flex
                                justify-content: center
                                input
                                    cursor: pointer
                                    text-align: center
                                    width: 80%
                                    height: 39px
                                    background: Transparent
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 35px
                                    border: none
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 20px
                                    color: black
                                    box-sizing: border-box
                            .after-success-order
                                margin-top: 21px
                                width: 295px
                                text-align: center
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 22px
                                color: green
                            .not-blame
                                margin-top: 21px
                                width: 293px
                                text-align: justify
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 14px
                                color: #9e9898
                                p
                                    text-align: center
                            .reservation
                                width: 295px
                                margin: 30px 0 0 0
                                display: flex
                                justify-content: center
                                button
                                    width: 80%
                                    height: 39px
                                    background: #000000
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 35px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #FFFDFD

@media (min-width: 769px) and (max-width: 992px)
    .housepage-wrap
        box-sizing: border-box
        position: relative
        .house-page-navbar
            position: relative
            .Navbar
                box-sizing: border-box
                position: relative
                max-width: 992px
                width: 100%
                .container
                    max-width: 992px
                    width: 100%
                    .navbar-card-wrap
                        display: none
    .housepage-wrap
        .container
            max-width: 992px
            width: 100%
            .main-titles
                display: none
            .main.house-page
                margin-top: 50px
                margin-bottom: 35px
                position: relative
                display: flex
                justify-content: space-around
                box-sizing: border-box
                padding: 0 25px
                .house-main.first-column
                    width: 392px
                    .house-main__image
                        img
                            border-radius: 0
                    .house-main__map
                        img
                            width: 100%
                    .house-description__date
                        margin-top: 30px
                        display: flex
                        flex-direction: column
                        align-items: flex-end
                        &__header
                            width: 392px
                            display: inline
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #1B1818
                        &__content
                            margin-top: 15px

                .house-main.third-column
                    display: none
                .house-main.second-column
                    width: auto
                    .house-description
                        &__date
                            display: none
                        &__faq
                            width: 295px
                            text-align: center
                        .mob-price-calc
                            margin-top: 30px
                            .price-calculate-form
                                margin: 0 0
                            .phone-input
                                width: 295px
                                margin: 30px 0 0 0
                                display: flex
                                justify-content: center
                                input
                                    cursor: pointer
                                    text-align: center
                                    width: 80%
                                    height: 39px
                                    background: Transparent
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 35px
                                    border: none
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 20px
                                    color: black
                                    box-sizing: border-box
                            .after-success-order
                                margin-top: 21px
                                width: 295px
                                text-align: center
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 20px
                                color: green
                            .phoneError
                                width: 293px
                                margin-top: 21px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                p
                                    text-align: center
                                    font-size: 20px
                                    color: red
                            .not-blame
                                margin-top: 21px
                                width: 293px
                                text-align: justify
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 14px
                                color: #9e9898
                                p
                                    text-align: center
                            .reservation
                                width: 295px
                                margin: 30px 0 0 0
                                display: flex
                                justify-content: center
                                button
                                    width: 80%
                                    height: 39px
                                    background: #000000
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 35px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #FFFDFD

@media (max-width: 768px)
    .housepage-wrap
        box-sizing: border-box
        position: relative
        .manager-contact-wrapper
            margin-top: 30px
            position: relative
            display: flex
            flex-direction: column
            justify-content: space-around
            align-items: center
            width: 295px
            height: auto
            background: #FFFDFD
            border: 1px solid #CFEE75
            box-sizing: border-box
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
            border-radius: 35px
            padding: 18px 23px 16px 27px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 20px
            color: #1B1818
            a
                font-size: 20px
        .house-page-navbar
            position: relative
            .Navbar
                box-sizing: border-box
                position: relative
                max-width: 768px
                width: 100%
                height: 71px
                .container
                    max-width: 768px
                    width: 100%
                    .navbar-card-wrap
                        display: none
        main
            position: relative
            margin: 0
            padding-left: 10px
            background: #000
            box-sizing: border-box
            .prompt
                display: none
            .container
                box-sizing: border-box
                background: #fff
                flex-direction: column
                position: relative
                padding-bottom: 74px
                .main.house-page
                    margin: 0
                    display: flex
                    flex-direction: column
                    box-sizing: border-box
                    position: relative
                    .house-main.first-column
                        display: flex
                        flex-direction: column-reverse
                        padding: 0
                        .house-main__name,.house-main__location
                            padding-left: 39px
                    .house-main
                        box-sizing: border-box
                        padding-left: 39px
                        width: 100%
                        padding-right: 25px
                        &__map
                            width: 100%
                            height: 370px
                            display: none
                        &__image
                            margin: 0
                            border-radius: 0
                            .slide-image-wrap
                                position: relative
                                height: auto
                                width: 100%
                                overflow: hidden
                                outline: none
                                .slick-slider
                                    position: relative
                                    height: 100%
                                    width: 100%
                                    .slick-list
                                        height: 100%
                                        width: 100%
                                        .slick-track
                                            height: 100%
                                            display: flex
                                            div
                                                height: 100%
                                                outline: none
                                                img
                                                    border-radius: 0
                                                    height: 320px
                        &__name
                            margin-top: 24px
                        &__location
                            margin-top: 10px
                    .house-main.second-column
                        margin-top: 10px
                        flex-direction: row
                        flex-wrap: wrap
                        .house-description
                            width: 100%
                            display: flex
                            flex-wrap: wrap
                            justify-content: space-between
                            &__descr
                                width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #434242
                                    height: auto
                            &__condition
                                // width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    height: auto
                                    .short-info
                                        p
                                            font-family: Roboto
                                            font-style: normal
                                            font-weight: normal
                                            font-size: 16px
                                            color: #434242
                                    .additional-info
                                        p
                                            font-family: Roboto
                                            font-style: normal
                                            font-weight: normal
                                            font-size: 16px
                                            color: #434242
                            &__special-conditions
                                // width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    p
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 16px
                                        color: #434242
                            &__faq
                                display: none
                            &__date
                                width: 100%
                                &__content
                                    width: 100%
                    .house-main.third-column
                        margin-top: 40px
                        display: flex
                        align-items: flex-start
                        .subhouses-block
                            display: flex
                            flex-direction: column
                            width: 100%
                            margin: 0 auto
                            &__header
                                p
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 28px
                                    color: #000000
                                    text-align: center
                            &__subheader
                                margin-top: 15px
                                p
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 18px
                                    text-align: center
                                    color: #000000
                            &__btn
                                margin: 15px auto 0
                                .write-btn
                                    background: #AAD729
                                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                    border-radius: 35px
                                    width: 150px
                                    height: 32px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 18px
                                    border: none
                                    color: #000000
                        .still-question
                            display: none
                        .price-calculate-form
                            main
                                background: #FFFDFD
                        .phone-input
                            width: 295px
                            margin: 30px 0 0 0
                            display: flex
                            justify-content: center
                            input
                                padding: 0
                                cursor: pointer
                                text-align: center
                                width: 80%
                                height: 39px
                                background: Transparent
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                border-radius: 35px
                                border: none
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 20px
                                color: black
                                box-sizing: border-box
                        .phoneError
                            width: 293px
                            margin-top: 21px
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            p
                                text-align: center
                                font-size: 20px
                                color: red
                        .not-blame
                            margin-top: 21px
                            width: 293px
                            text-align: justify
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 14px
                            color: #9e9898
                            p
                                text-align: center
                        .reservation
                            width: 295px
                            margin: 30px 0 0 0
                            display: flex
                            justify-content: center
                            button
                                width: 80%
                                height: 39px
                                background: #000000
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
                                border-radius: 35px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 16px
                                color: #FFFDFD
                .main-titles
                    height: 30px
                    display: flex
                    z-index: 111
                    position: relative
                    width: 100%
                    display: flex
                    padding: 0
                    background: #000
                    font-family: Roboto
                    font-style: normal
                    font-weight: 300
                    font-size: 16px
                    color: #C4C4C4
                    justify-content: space-around
                    border-bottom: 4px solid #CFEE75
                    li
                        width: 100%
                        margin: 0
                        display: flex
                        justify-content: center
                        align-items: center
                        height: 30px
                    .lodging
                        background: #000000
                        border: 1px solid #DADADA
                        border-bottom: 0px
                        box-sizing: border-box
                        border-radius: 10px 180px 0px 0px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 500
                        font-size: 16px
                        color: #E6FEA1
                        margin: 0

@media (max-width: 576px)
    .housepage-wrap

        .house-page-navbar
            position: relative
            .Navbar
                position: relative
                max-width: 576px
                width: 100%
                height: 71px
                .container
                    max-width: 576px
                    width: 100%
                    .navbar-card-wrap
                        display: none
        main
            .container
                .main-titles
                    height: 22px
                    display: flex
                    z-index: 111
                    position: relative
                    width: 100%
                    display: flex
                    padding: 0
                    background: #000
                    font-family: Roboto
                    font-style: normal
                    font-weight: 300
                    font-size: 14px
                    color: #C4C4C4
                    justify-content: space-around
                    border-bottom: 4px solid #CFEE75
                    li
                        width: 100%
                        margin: 0
                        display: flex
                        justify-content: center
                        align-items: center
                        height: 22px
                    .lodging
                        background: #000000
                        border: 1px solid #DADADA
                        border-bottom: 0px
                        box-sizing: border-box
                        border-radius: 10px 180px 0px 0px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 500
                        font-size: 16px
                        color: #E6FEA1
                        margin: 0
                .main.house-page
                    display: flex
                    flex-direction: column
                    .second-column
                        .house-description
                            &__condition
                                &__content
                                    height: auto

                    .first-column
                        display: flex
                        flex-direction: column-reverse

@media (max-width: 360px)
    .housepage-wrap
        .house-page-navbar
            position: relative
            .Navbar
                position: relative
                max-width: 360px
                width: 100%
                height: 71px
                .container
                    max-width: 360px
                    width: 100%
                    .navbar-card-wrap
                        display: none
        main
            position: relative
            margin: 0
            padding-left: 10px
            background: #000
            box-sizing: border-box
            .container
                box-sizing: border-box
                background: #fff
                flex-direction: column
                position: relative
                padding-bottom: 74px
                .main.house-page
                    margin: 0
                    display: flex
                    flex-direction: column
                    box-sizing: border-box
                    position: relative
                    .house-main.first-column
                        display: flex
                        flex-direction: column-reverse
                        padding: 0
                        .house-main__name,.house-main__location
                            padding-left: 39px
                    .house-main
                        box-sizing: border-box
                        padding-left: 39px
                        width: 100%
                        padding-right: 25px
                        &__map
                            display: none
                        &__image
                            margin: 0
                            border-radius: 0
                            img
                                border-radius: 0
                        &__name
                            margin-top: 24px
                        &__location
                            margin-top: 10px
                    .house-main.second-column
                        margin-top: 10px
                        flex-direction: row
                        flex-wrap: wrap
                        .house-description
                            display: flex
                            flex-wrap: wrap
                            justify-content: space-between
                            &__descr
                                width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #434242
                            &__condition
                                // width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    .short-info
                                        p
                                            font-family: Roboto
                                            font-style: normal
                                            font-weight: normal
                                            font-size: 16px
                                            color: #434242
                                    .additional-info
                                        p
                                            font-family: Roboto
                                            font-style: normal
                                            font-weight: normal
                                            font-size: 16px
                                            color: #434242
                            &__special-conditions
                                // width: 100%
                                &__header
                                    font-size: 20px
                                &__content
                                    p
                                        font-family: Roboto
                                        font-style: normal
                                        font-weight: normal
                                        font-size: 16px
                                        color: #434242
                            &__faq
                                display: none
                    .house-main.third-column
                        padding-left: 10px
                        margin-top: 10px
                        display: flex
                        align-items: flex-start
                        .still-question
                            display: none
                        .price-calculate-form
                            main
                                background: #FFFDFD
                .main-titles
                    height: 22px
                    display: flex
                    z-index: 111
                    position: relative
                    width: 100%
                    display: flex
                    padding: 0
                    background: #000
                    font-family: Roboto
                    font-style: normal
                    font-weight: 300
                    font-size: 14px
                    color: #C4C4C4
                    justify-content: space-around
                    border-bottom: 4px solid #CFEE75
                    li
                        width: 100%
                        margin: 0
                        display: flex
                        justify-content: center
                        align-items: center
                        height: 22px
                    .lodging
                        background: #000000
                        border: 1px solid #DADADA
                        border-bottom: 0px
                        box-sizing: border-box
                        border-radius: 10px 180px 0px 0px
                        font-family: Roboto
                        font-style: normal
                        font-weight: 500
                        font-size: 14px
                        color: #E6FEA1
                        margin: 0
