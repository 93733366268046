.modal_slide-image-wrap
    position: relative
    max-width: calc(100vw - 20px)
    max-height: 100vh
    overflow: hidden
    outline: none
    .prewClick,.nextClick
        z-index: 111
        cursor: pointer
        height: 25px
        width: 15px
        background: Transparent
        border: none
        outline: none
        button
            background: Transparent
            border: none
            outline: none
            width: 100%
            height: 100%
    .prewClick
        position: absolute
        left: 25px
        bottom: 43%
        img
            cursor: pointer
    .nextClick
        cursor: pointer
        position: absolute
        right: 25px
        bottom: 43%
        img
            cursor: pointer
    .slick-slider
        position: relative
        height: 100%
        width: 100%
        .slick-list
            height: 100%
            width: 100%
            .slick-track
                height: 100%
                display: flex
                div
                    display: flex
                    align-items: center
                    justify-content: center
                    height: 100%
                    width: 100%
                    outline: none
                    img.modal-slider-img
                        max-width: calc(100vw - 20px)
                        max-height: 100vh
                        height: 100% !important
                        width: auto !important
                        z-index: 1

@media (max-width: 768px)
    .modal_slide-image-wrap
        .prewClick,.nextClick
            z-index: 111
            cursor: pointer
            height: 25px
            width: 15px
            background: Transparent
            border: none
            outline: none
            button
                background: Transparent
                border: none
                outline: none
                width: 100%
                height: 100%
        .prewClick
            position: absolute
            left: 25px
            bottom: 43%
            img
                cursor: pointer
        .nextClick
            cursor: pointer
            position: absolute
            right: 25px
            bottom: 43%
            img
                cursor: pointer
        .slick-slider
            position: relative
            width: 100%
            .slick-list
                width: 100%
                .slick-track
                    display: flex
                    div
                        display: flex
                        align-items: center
                        justify-content: center
                        height: auto !important
                        width: 100%
                        outline: none
                        img.modal-slider-img
                            max-width: calc(100vw - 20px)
                            max-height: 100vh
                            height: auto !important
                            width: auto !important
                            z-index: 1
