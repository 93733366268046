*
    margin: 0
    padding: 0
nav.manageNavbar
    position: relative
    display: flex
    width: 100%
    height: 54px
    justify-content: space-between
    background: #fff
    box-shadow: 0 2px 20px rgb(0 0 0 / 4%)
    .navbar-section
        display: flex
    .navbar-left
        height: 100%
        .navbar-brand
            background: #263238
            width: 250px
            display: flex
            box-sizing: border-box
            justify-content: flex-start
            align-items: center
            padding-left: 15px
            h2
                font-size: 20px
                color: #fff
                font-weight: 700
        .navbar-tools
            height: 100%
            .tools-list
                height: 100%
                list-style-type: none
                display: flex
                align-items: center
                &__item
                    border: none
                    background: transparent
                    cursor: pointer
                    margin-left: 30px
    .navbar-right
        .navbar-links
            .links-list
                height: 100%
                list-style-type: none
                display: flex
                align-items: center
                &__item
                    margin-right: 30px
