.filters
    position: absolute
    top: 0
    left: -2px
    height: auto
    padding-bottom: 14px
    width: 386px
    background: #7A7A7A
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 20px 25px 0px
    z-index: 1000
    .filter-card
        position: relative
        display: block
        .exit-icon
            position: absolute
            top: 7px
            right: 12px
            align-self: center
            background-color: Transparent
            background-repeat: no-repeat
            border: none
            cursor: pointer
            overflow: hidden
        .filter-header-text
            margin-left: 20px
            margin-top: 6px
            font-family: Roboto
            font-style: normal
            font-weight: bold
            font-size: 14px
            color: #FFFDFD
        .filter-sub-cost
            display: flex
            align-items: center
            justify-content: flex-end
            margin-right: 42px
            height: 16px
            p
                // line-height: 16px
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 10px
                color: #FFFFFF
            &__interval
                display: flex
                align-items: center
                margin-left: 5px
                label
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 10px
                    color: #CFEE75
                input
                    color: #CFEE75
                    text-align: center
                    margin-left: 5px
                    background: #969595
                    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                    border-radius: 30px
                    width: 58px
                    height: 16px
                    border: none
                    outline: none

        .filter-subheader-text
            position: relative
            margin-top: 18px
            margin-left: 24px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 14px
            color: #E6FEA1
        .card-column-wrap
            display: flex
            position: relative
            margin-left: 20px
            .filter-card-main
                display: flex
                width: 50%
                flex-direction: column
                justify-content: space-between
                .filters-wrap
                    &__header
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 12px
                        color: #FFFDFD
                        margin-bottom: 5px
                        margin-top: 14px
                        margin-left: 4px
                    &__main
                        .filter-item
                            margin-top: 1px
                            display: flex
                            align-items: center
                            input
                                margin-left: 6px
                                width: 18px
                                height: 10px
                                background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC
                                border-radius: 30px
                                border: none
                                outline: none
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 7px
                                color: #1B1818
                                text-align: center
                            .filter-icon
                                width: 14px
                                display: flex
                                justify-content: center
                            p
                                margin-left: 6px
                                width: 114px
                                font-family: Roboto
                                font-style: normal
                                font-weight: normal
                                font-size: 10px
                                color: #FFFFFF
                            .react-switch
                                margin-left: 6px
                                &-bg
                                    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
    .search-btn
        width: 61px
        height: 17px
        background: rgba(255, 255, 255, 0.2)
        border: 1px solid #AAD729
        box-sizing: border-box
        border-radius: 30px
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 10px
        color: #FFFFFF
        float: right
        margin-top: 18px
        margin-right: 24px
.filters-wrap.rent,.filters-wrap.conditions
    .filters-wrap__header
        color: #E6FEA1 !important

@media (min-width: 768px)
    .filters
        position: absolute
        top: 0
        left: -2px
        height: auto
        padding-bottom: 14px
        width: 600px
        background: #7A7A7A
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
        border-radius: 20px 25px 0px
        z-index: 1000
        .filter-card
            position: relative
            display: block
            .exit-icon
                position: absolute
                top: 7px
                right: 12px
                align-self: center
                background-color: Transparent
                background-repeat: no-repeat
                border: none
                cursor: pointer
                overflow: hidden
            .filter-header-text
                margin-left: 20px
                margin-top: 6px
                font-family: Roboto
                font-style: normal
                font-weight: bold
                font-size: 14px
                color: #FFFDFD
            .filter-sub-cost
                display: flex
                align-items: center
                justify-content: flex-end
                margin-right: 100px
                height: 16px
                p
                    // line-height: 16px
                    font-family: Roboto
                    font-style: normal
                    font-weight: normal
                    font-size: 16px
                    color: #FFFFFF
                &__interval
                    display: flex
                    align-items: center
                    margin-left: 5px
                    label
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 16px
                        color: #CFEE75
                    input
                        color: #CFEE75
                        text-align: center
                        margin-left: 5px
                        background: #969595
                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 30px
                        width: 58px
                        height: 22px
                        border: none
                        outline: none
                        font-size: 16px

            .filter-subheader-text
                position: relative
                margin-top: 18px
                margin-left: 24px
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 18px
                color: #E6FEA1
            .card-column-wrap
                display: flex
                position: relative
                margin-left: 20px
                .filter-card-main
                    display: flex
                    width: 50%
                    flex-direction: column
                    justify-content: space-between
                    .filters-wrap
                        &__header
                            font-family: Roboto
                            font-style: normal
                            font-weight: normal
                            font-size: 18px
                            color: #FFFDFD
                            margin-bottom: 5px
                            margin-top: 14px
                            margin-left: 4px
                        &__main
                            .filter-item
                                margin-top: 4px
                                display: flex
                                align-items: center
                                input
                                    margin-left: 6px
                                    width: 30px
                                    height: 16px
                                    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC
                                    border-radius: 30px
                                    border: none
                                    outline: none
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #1B1818
                                    text-align: center
                                .filter-icon
                                    width: 16px
                                    display: flex
                                    justify-content: center
                                    img
                                        width: 100%
                                p
                                    margin-left: 6px
                                    width: 200px
                                    font-family: Roboto
                                    font-style: normal
                                    font-weight: normal
                                    font-size: 16px
                                    color: #FFFFFF
                                .react-switch
                                    margin-left: 6px
                                    &-bg
                                        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #E5DDC1 54.17%, #B3DA44 100%), #ECECEC !important
        .search-btn
            width: 61px
            height: 17px
            background: rgba(255, 255, 255, 0.2)
            border: 1px solid #AAD729
            box-sizing: border-box
            border-radius: 30px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 10px
            color: #FFFFFF
            float: right
            margin-top: 18px
            margin-right: 24px

@media (max-width: 426px)
    .filters
        width: 260px
        height: auto
        padding-bottom: 10px
        padding-left: 15px
        .filter-card
            position: relative
            width: 100%
            .filter-sub-cost
                width: 100%
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 12px
                color: #FFFFFF
                display: flex
                align-items: center
                justify-content: flex-start
                margin-top: 10px
                &__interval
                    margin-left: 5px
                    label
                        font-family: Roboto
                        font-style: normal
                        font-weight: normal
                        font-size: 12px
                        color: #CFEE75
                    input
                        color: #CFEE75
                        text-align: center
                        margin-left: 5px
                        background: #969595
                        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25)
                        border-radius: 30px
                        width: 58px
                        height: 16px
                        border: none
                        outline: none
            .filter-subheader-text
                margin-left: 0  
            .card-column-wrap
                flex-direction: column
                width: 100%
                font-size: 12px !important
                margin: 0
                .filter-card-main
                    .filters-wrap
                        &__header
                            font-size: 14px
                        &__main
                            .filter-item
                                margin-top: 3px
                                width: 250px
                                input
                                    height: 16px
                                    width: 30px
                                    font-size: 10px
                                .filter-icon
                                    width: 20px
                                    img
                                        width: 20px
                                p
                                    width: 200px
                                    font-size: 14px

            .search-btn
                width: 130px
                height: 28px
                font-family: Roboto
                font-style: normal
                font-weight: normal
                font-size: 14px
                line-height: 16px
                color: #FFFFFF
