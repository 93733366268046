@keyframes slideOn
    0%
        transform: translateX(0) scale(1)
    50%
        transform: translateX(10px) scale(1.2)
    100%
        transform: translateX(20px) scale(1)

@keyframes slideOff
    0%
        transform: translateX(20px) scale(1)
    50%
        transform: translateX(10px) scale(1.2)
    100%
        transform: translateX(0) scale(1)

.manageCalendarSearchFilters
    .DropDownContainer
        .DropDownListContainer
            display: flex
            flex-direction: row
            justify-content: space-between
            padding-bottom: 40px
            ul.Dropdown-first-column
                margin-right: 15px
            ul.DropDownList
                width: 100%
                list-style-type: none
                display: flex
                flex-direction: column
                position: relative
                li.toggle-container.input-container
                    input.input-container_input
                        position: relative
                        background-color: #d0d0d0
                        border-radius: 50px
                        cursor: pointer
                        text-align: center
                        display: flex
                        justify-content: center
                        align-items: center
                        margin: 0 15px 0
                        width: 40px
                        height: 20px
                        border: none
                        outline: none
                li.toggle-container
                    list-style-type: none
                    display: flex
                    align-items: center
                    margin: 10px 0
                    position: relative
                    span    
                        width: calc(100% - 70px)
                    .toggle-container__toggle
                        visibility: hidden
                        display: none
                    .toggle-container__label
                        position: relative
                        background-color: #d0d0d0
                        border-radius: 50px
                        cursor: pointer
                        display: inline-block
                        margin: 0 15px 0
                        width: 40px
                        height: 20px
                        .ball
                            background: #fff
                            height: 17px
                            width: 17px
                            border-radius: 50%
                            position: absolute
                            top: 1.5px
                            left: 2px
                            align-items: center
                            justify-content: center
                            animation: slideOff 0.3s linear forwards
                    .toggle-container__toggle:checked +.toggle-container__label
                        background-color: #004CCC
                    .toggle-container__toggle:checked +.toggle-container__label
                        .ball
                            animation: slideOn 0.3s linear forwards
